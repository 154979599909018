import React from 'react';

import { useTranslation } from '@almond/localization';
import { Button, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { useWaitlistModalState } from '~modules/state';

import { themedPhysicianStyles } from './styles';

export type JoinWaitlistButtonProps = {
  isCentered?: boolean;
};

export const JoinWaitlistButton = (props: JoinWaitlistButtonProps) => {
  const { isCentered = true } = props;
  const [state, dispatch] = useWaitlistModalState();
  const [styles] = useTheme(themedPhysicianStyles);
  const { isMobile } = useBrowserTypeMap();
  const { t } = useTranslation();

  if (state.isComplete) {
    return (
      <Text fontStyle="medium" style={[styles.waitlistText, styles.selfCenter]}>
        {t('schedulingNew.waitlistJoined')}
      </Text>
    );
  }

  return (
    <Button
      style={[styles.waitlistButton, isCentered && styles.selfCenter, isMobile && styles.waitlistButtonMobile]}
      labelStyle={styles.waitlistText}
      size="s"
      onPress={() => dispatch({ type: 'open' })}
    >
      {t('schedulingNew.joinWaitlist')}
    </Button>
  );
};
