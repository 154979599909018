import React, { useState } from 'react';
import { Image, View } from 'react-native';

import { Button, Modal, ScrollView, sizes, SkeletonLoader, Text, useTheme } from '@almond/ui';

import { PhysicianInfo } from './PhysicianInfo';

import { themedStyles } from './styles';

import type { PhysicianFindOut } from '@almond/api-types';
import type { StyleProp, ViewStyle } from 'react-native';

export type ReadBioProps = {
  style?: StyleProp<ViewStyle>;
  physician: PhysicianFindOut;
};

export const ReadBioButtonOld: React.FC<ReadBioProps> = props => {
  const { physician, style } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [styles] = useTheme(themedStyles);

  const toggleIsVisible = () => {
    setIsVisible(prevState => !prevState);
  };

  return (
    <View style={[styles.readBioBtn, style]}>
      <Button
        onPress={toggleIsVisible}
        labelStyle={styles.bioBtnLabel}
        size="xxs"
        fontStyle="bold"
        type="secondary"
        uppercase
        fixedWidth={false}
      >
        {'Read bio'}
      </Button>
      <Modal isVisible={isVisible} onRequestClose={toggleIsVisible} containerStyle={styles.modal} showClose>
        <ScrollView contentContainerStyle={styles.modalContent}>
          <Image source={{ uri: physician.profileImage }} style={styles.headshotMobile} alt="" />
          <View style={styles.columnMobile}>
            <Text style={styles.darkText} fontStyle="bold" size="xxl">
              {`${physician.firstName} ${physician.lastName}`}
            </Text>
            <Text size="m" style={styles.darkText}>
              {physician.title}
            </Text>
          </View>
          <PhysicianInfo isLargerText info={physician.knowYourProvider} isExpanded />
        </ScrollView>
      </Modal>
    </View>
  );
};

export const LoadingReadBioButton = (props: Pick<ReadBioProps, 'style'>) => {
  const [styles] = useTheme(themedStyles);

  return (
    <View style={[styles.readBioBtn, props.style]}>
      <SkeletonLoader.RoundedRectangle width={88} height={sizes.SPACING_L} roundness={sizes.SPACING_L} />
    </View>
  );
};
