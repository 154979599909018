import { useCallback, useMemo } from 'react';

import useSWRImmutable from 'swr/immutable';

import { PHYSICIANS_PATH, physiciansApi } from '~modules/api';

import { useVisitReasons } from './useVisitReasons';

export const useGetProvidersForCurrentVisitReason = (location: 'video' | 'office' | null) => {
  const { visitReason, isLoadingVisitReasons, mutateVisitReasons, visitReasonsError } = useVisitReasons();
  const { data, isLoading, mutate, error } = useSWRImmutable(PHYSICIANS_PATH, physiciansApi.listPhysicians);

  const physicians = useMemo(() => {
    if (!visitReason) {
      return undefined;
    }

    return data?.physicians?.filter(p => {
      if (!p.visitReasons.includes(visitReason.code)) {
        return false;
      }

      if (location === 'video' && !p.takesTelehealthAppointment) {
        return false;
      }

      if (location === 'office' && !p.takesInPersonAppointment) {
        return false;
      }

      return true;
    });
  }, [data?.physicians, visitReason, location]);

  const retry = useCallback(() => {
    mutateVisitReasons();
    mutate();
  }, [mutate, mutateVisitReasons]);

  return { physicians, isLoading: isLoadingVisitReasons || isLoading, error: visitReasonsError || error, retry };
};
