import React, { useState } from 'react';
import { View } from 'react-native';

import { PulseProvider, useBrowserTypeMap, useTheme } from '@almond/ui';

import { Physician } from './Physician';
import { Timeslots } from './Timeslots';

import { themedContainerStyles } from './styles';

import type { AppointmentTypeDetails, AvailableSlot, PhysicianFindOut } from '@almond/api-types';
import type { Dayjs } from 'dayjs';
import type { StyleProp, ViewStyle } from 'react-native';

export type AvailabilitySectionProps = {
  physician: PhysicianFindOut;
  appointmentDetails: AppointmentTypeDetails;
  selectedDate: Dayjs;
  timeSlots: readonly AvailableSlot[];
  onTimeSlotSelect: (timeSlot: AvailableSlot) => void;
  onGoToFirstVisitPress: (firstVisitDate: Dayjs) => void;
  firstVisitOn?: Dayjs;
  isLoading: false;
  style?: StyleProp<ViewStyle>;
  isPrimary: boolean;
};

export type AvailabilitySectionLoadingProps = Omit<Partial<AvailabilitySectionProps>, 'isLoading'> & {
  isLoading: true;
};

const AvailabilitySectionLoading: React.FC<Pick<AvailabilitySectionProps, 'style'>> = props => {
  const [styles] = useTheme(themedContainerStyles);
  const { isMobile } = useBrowserTypeMap();

  return (
    <PulseProvider duration={1000}>
      <View style={props.style}>
        <View style={[styles.container, isMobile && styles.containerMobile]}>
          <Physician isLoading />
          <Timeslots isLoading />
        </View>
      </View>
    </PulseProvider>
  );
};

export const AvailabilitySectionOld: React.FC<AvailabilitySectionProps | AvailabilitySectionLoadingProps> = props => {
  const [styles] = useTheme(themedContainerStyles);
  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobile } = useBrowserTypeMap();

  if (props.isLoading) {
    return <AvailabilitySectionLoading style={props.style} />;
  }

  const {
    physician,
    appointmentDetails,
    selectedDate,
    timeSlots,
    onTimeSlotSelect,
    onGoToFirstVisitPress,
    firstVisitOn,
    isLoading,
    style,
    isPrimary,
  } = props;

  const toggleExpand = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <View
      style={[
        styles.container,
        isMobile && styles.containerMobile,
        isMobile && !timeSlots.length && styles.containerMobileWithoutTimeSlots,
        style,
      ]}
      testID="SchedulingAvailabilitySection"
      // dataSet isn't in react-native type definitions, but it's supported in react-native-web.
      // https://github.com/necolas/react-native-web/releases/tag/0.13.0
      // @ts-expect-error
      dataSet={{ physician: physician.uuid, appointmentType: appointmentDetails.appointmentTypeUuid }}
    >
      <Physician
        physician={physician}
        duration={appointmentDetails.duration}
        location={appointmentDetails.location}
        isExpanded={isExpanded}
        onToggleExpand={toggleExpand}
        isLoading={isLoading}
        isPrimary={isPrimary}
      />
      <Timeslots
        isPrimary={isPrimary}
        selectedDate={selectedDate}
        timeSlots={timeSlots}
        isExpanded={isExpanded}
        onToggleExpand={toggleExpand}
        onSelect={onTimeSlotSelect}
        onGoToFirstVisitPress={onGoToFirstVisitPress}
        firstVisitOn={firstVisitOn}
        location={appointmentDetails.location}
        duration={appointmentDetails.duration}
        isLoading={isLoading}
      />
    </View>
  );
};
