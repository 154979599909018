import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  sectionTitle: {
    marginTop: sizes.SPACING_M,
    marginBottom: sizes.SPACING_S,
  },
  loaderContainer: {
    rowGap: sizes.SPACING_S,
  },
  topAlign: {
    // If this is 100% it'll be too close to the top, so only go 70%
    minHeight: '70%',
  },

  visitReasonPickerContainer: {
    ...getShadow(),
    backgroundColor: theme.background,
    borderRadius: sizes.SPACING_M,
    padding: sizes.SPACING_XL,
    paddingTop: sizes.SPACING_M,
    gap: 8,
  },
  visitReasonPickerTitle: {
    color: theme.accent,
    marginBottom: sizes.SPACING_M,
  },

  visitReasonPicker: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: sizes.SPACING_S,
  },

  instructions: {
    textAlign: 'center',
    color: theme.secondaryTextDark,
    marginBottom: sizes.SPACING_S,
    marginTop: sizes.SPACING_L,
  },
  returningMember: {
    textAlign: 'center',
    marginTop: 12,
    color: theme.secondaryTextDark,
  },
}));
