import React from 'react';
import { Image } from 'react-native';

import { BaseButton, useBrowserTypeMap, useTheme } from '@almond/ui';

import { themedPhysicianStyles } from './styles';

import type { PhysicianFindOut } from '@almond/api-types';

export type HeadshotProps = {
  physician: PhysicianFindOut;
  onPress: () => void;
};

export const Headshot: React.FC<HeadshotProps> = props => {
  const [styles] = useTheme(themedPhysicianStyles);
  const { physician, onPress } = props;
  const { isMobile } = useBrowserTypeMap();

  return (
    <BaseButton onPress={onPress} style={isMobile ? styles.headshotMobile : styles.headshotDesktop}>
      <Image source={{ uri: physician.profileImage }} style={styles.headshot} role="presentation" />
    </BaseButton>
  );
};
