import { StyleSheet } from 'react-native';

import { sizes, styleSheetFactory } from '@almond/ui';

export const themedContainerStyles = styleSheetFactory(theme => ({
  container: {
    flex: 1,
    flexGrow: 0,
    minHeight: 'auto',
    rowGap: 10,
    paddingBottom: 10,
  },

  recommendedContainer: {
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.selectedOption,
  },

  recommendedContainerMobile: {
    marginBottom: 12,
    borderRadius: 20,
  },

  bottomBorder: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: theme.divider,
  },

  recommendedBanner: {
    height: 34,
    width: '100%',
    backgroundColor: theme.selectedOption,
    justifyContent: 'center',
    paddingHorizontal: 16,
  },

  recommendedBannerText: {
    color: theme.lightText,
  },
}));

export const themedPhysicianStyles = styleSheetFactory(theme => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: sizes.SPACING_S,
    paddingTop: 16,
    paddingHorizontal: 40,
  },

  containerMobile: {
    paddingHorizontal: 16,
  },

  contentContainer: {
    flex: 1,
    rowGap: sizes.SPACING_XXS,
  },

  headshotDesktop: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },

  headshotMobile: {
    width: 64,
    height: 64,
    borderRadius: 32,
  },

  headshot: {
    width: '100%',
    height: '100%',
  },

  title: {
    color: theme.darkText,
  },

  subtitle: {
    color: theme.darkText,
  },

  readBioButton: {
    marginTop: sizes.SPACING_XXS,
  },

  waitlistButton: {
    backgroundColor: theme.lightPrimary,
    width: '100%',
  },

  waitlistButtonMobile: {
    width: 134,
  },

  waitlistText: {
    color: theme.borderTextInput,
  },

  selfCenter: {
    alignSelf: 'center',
  },
}));

export const themedTimeSlotsStyles = styleSheetFactory(theme => ({
  timeSlots: {
    gap: 10,
    marginTop: 34,
    minHeight: 82,
    paddingHorizontal: 40,
  },

  timeSlotsDesktop: {
    display: 'grid' as any,
    gridTemplateColumns: 'repeat(5, minmax(84px, 1fr))',
  },

  timeSlotsMobile: {
    minHeight: 0,
    marginTop: 8,
    paddingHorizontal: 16,
    flexDirection: 'row',
  },

  timeSlot: {
    backgroundColor: theme.slot,
    borderWidth: 1,
    borderColor: theme.primary,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 18,
    paddingHorizontal: sizes.SPACING_S,
    width: 84,
    height: 36,
  },

  timeSlotText: {
    color: theme.darkText,
    textAlign: 'center',
  },

  noAvailability: {
    color: theme.darkText,
    alignSelf: 'center',
    textAlign: 'center',
    marginVertical: 32,
  },

  showMoreButton: {
    padding: sizes.SPACING_S,
  },

  footer: {
    paddingHorizontal: 40,
    rowGap: 10,
  },

  footerMobile: {
    paddingHorizontal: 16,
  },
}));
