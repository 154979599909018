import React from 'react';
import { useWindowDimensions, View } from 'react-native';

import { Button, SkeletonLoader, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import Head from 'expo-router/head';

import { InfoMessage, LocalImage, MessageContainer } from '~modules/ui';

import { Container } from '../Container';

import { themedStyles } from './styles';

import type { ContainerProps } from '../Container';
import type { ButtonProps } from '@almond/ui';

type InterstitialLayoutProps = {
  id: ContainerProps['id'];
  backgroundDecoration?: ContainerProps['backgroundDecoration'];
  title: string;
  description: React.ReactNode;
  submitButtonProps: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  showImage?: boolean;
  footer?: React.ReactNode;
  isLoading?: boolean;
  statusBar?: React.ReactNode;
};

export const InterstitialLayout = (props: InterstitialLayoutProps) => {
  const {
    id,
    backgroundDecoration,
    title,
    description,
    submitButtonProps,
    secondaryButtonProps,
    showImage: showImageInitial = true,
    footer,
    isLoading,
    statusBar,
  } = props;
  const [styles] = useTheme(themedStyles);
  const showImage = showImageInitial && useWindowDimensions().width > 800;
  const { isMobile } = useBrowserTypeMap();

  const buttons = (
    <View style={styles.buttonContainer}>
      <Button testID="Submit" style={styles.button} {...submitButtonProps} />
      {secondaryButtonProps && (
        <Button testID="Secondary" style={styles.button} type="secondary" {...secondaryButtonProps} />
      )}
    </View>
  );

  const desktopContent = isLoading ? (
    <SkeletonLoader.RoundedRectangle width={640} height={351} roundness={20} />
  ) : (
    <>
      <InfoMessage
        style={styles.desktopContainer}
        title={title}
        description={
          <>
            {typeof description === 'string' ? (
              <Text size="l" style={styles.center}>
                {description}
              </Text>
            ) : (
              description
            )}

            {buttons}
          </>
        }
      />
      {footer}
    </>
  );

  const mobileContent = (
    <View style={styles.mobileContainer}>
      {isLoading ? (
        <SkeletonLoader.RoundedRectangle width={340} height={274} roundness={20} />
      ) : (
        <>
          <View style={styles.mobileInnerContainer}>
            <Text size="xxxxl" fontStyle="bold" style={styles.center}>
              {title}
            </Text>

            <MessageContainer>
              {typeof description === 'string' ? (
                <Text size="xl" style={styles.center}>
                  {description}
                </Text>
              ) : (
                description
              )}
            </MessageContainer>
          </View>
          {buttons}
          {footer}
        </>
      )}
    </View>
  );

  return (
    <>
      <Head>
        <title>{`${title} - Almond ObGyn`}</title>
      </Head>
      <Container id={id} size="XL" backgroundDecoration={backgroundDecoration} statusBar={statusBar}>
        {showImage && <LocalImage source="plant2" resizeMode="contain" style={styles.image} />}
        {isMobile ? mobileContent : desktopContent}
      </Container>
    </>
  );
};
