import { z } from 'zod';

import { DEFAULT_PROVIDER_TYPES } from '~modules/api';

import type { VisitReasonOut } from '@almond/api-types';

const legacyVisitOutcomeSchema = z.object({
  isTelehealth: z.union([z.null(), z.boolean()]),
  providerTypes: z.union([z.null(), z.enum([...DEFAULT_PROVIDER_TYPES, 'CG', 'MEXP', 'FOUNDER']).array()]),
  copy: z.union([z.null(), z.string()]),
  duration: z.number(),
  inSentence: z.union([z.null(), z.string()]),
});

const newVisitOutcomeSchema = z.object({
  // TODO remove provider types
  providerTypes: z.union([z.null(), z.enum([...DEFAULT_PROVIDER_TYPES, 'CG', 'MEXP', 'FOUNDER']).array()]),

  isTelehealth: z.boolean(),
  recommendedProviders: z.string().array().optional(),
  copy: z.union([z.null(), z.string()]),
  duration: z.number(),
  inSentence: z.union([z.null(), z.string()]),
  visitLocationLocked: z.boolean().optional(),
  disclaimerCopy: z.union([z.null(), z.string()]).optional(),
});

const visitOutcomeSchema = z.union([newVisitOutcomeSchema, legacyVisitOutcomeSchema]);

const infectionVisitOutcomeSchema = z
  .object({
    showAll: z.boolean(),
    infectionOutcome: z.boolean(),
    schema: visitOutcomeSchema,
  })
  .array();

export type VisitOutcome = z.infer<typeof visitOutcomeSchema>;

export const getVisitOutcome = (
  visitReason: VisitReasonOut,
  infectionConfig: { infectionOutcome: boolean; showAll: boolean }
): VisitOutcome | undefined => {
  if (visitReason.acuityBookingUrl) {
    return undefined;
  }

  if (!visitReason.configuration) {
    throw new Error(`Missing configuration for visit reason ${visitReason.code}`);
  }

  try {
    return visitOutcomeSchema.parse(visitReason.configuration);
  } catch (e) {
    try {
      const parsedConfig = infectionVisitOutcomeSchema.parse(visitReason.configuration.config);
      const finalConfig = parsedConfig.find(
        d => d.infectionOutcome === infectionConfig.infectionOutcome && d.showAll === infectionConfig.showAll
      )!.schema;

      if (finalConfig) {
        return finalConfig;
      }
    } catch (err) {
      throw new Error(`Invalid config for visit reason ${visitReason.code}`, { cause: err });
    }

    throw new Error(`No final visitOutcome config for visit reason ${visitReason.code}`);
  }
};
