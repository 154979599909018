import React, { useRef, useState } from 'react';
import { Pressable, View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { MaterialIcon, Popover, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { ScrollableCalendar } from '~modules/ui';

import { BottomSheet } from './BottomSheet';

import { calendarThemedStyles } from './styles';

import type { useMonthlyRequest } from './useMonthlyRequest';
import type { ScrollableCalendarProps } from '~modules/ui';
import type { Dayjs } from 'dayjs';

export type JumpToDatePickerProps = Pick<ScrollableCalendarProps, 'onDateSelect' | 'numVisible'> &
  Pick<ReturnType<typeof useMonthlyRequest>, 'availableDates' | 'requestDataAt'> & {
    selectedDate: Dayjs;
  };

export const JumpToDatePicker = (props: JumpToDatePickerProps) => {
  const [styles] = useTheme(calendarThemedStyles);
  const { onDateSelect, numVisible, availableDates, requestDataAt, selectedDate } = props;
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const { t } = useTranslation();

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState);
  };

  const handleDateSelect = (date: Dayjs) => {
    toggleVisibility();
    onDateSelect?.(date);
  };

  const calendar = (
    <ScrollableCalendar
      date={selectedDate}
      onDateSelect={handleDateSelect}
      numVisible={numVisible}
      availableDates={availableDates}
      requestDataAt={requestDataAt}
    />
  );

  return (
    <>
      <View style={styles.triggerContainer} ref={ref}>
        <Pressable
          style={styles.trigger}
          onPress={toggleVisibility}
          aria-expanded={isVisible}
          role="button"
          aria-controls="calendarPopover"
          testID="Scheduling_OpenCalendar"
        >
          <Text size="xl" numberOfLines={1}>
            {`${t('schedulingNew.jumpToDatePicker.jumpTo')} `}
            <Text size="xl" fontStyle="bold" style={styles.triggerLabel}>
              {t('schedulingNew.jumpToDatePicker.date')}
            </Text>
          </Text>
          <MaterialIcon source="keyboard-arrow-down" size={24} color="secondaryTextDark" />
        </Pressable>
      </View>
      {isDesktop && (
        <Popover anchorElement={ref} isVisible={isVisible} onRequestClose={toggleVisibility}>
          <View style={styles.popover} id="calendarPopover">
            {calendar}
          </View>
        </Popover>
      )}
      {isMobile && (
        <BottomSheet isVisible={isVisible} onRequestClose={toggleVisibility}>
          {calendar}
        </BottomSheet>
      )}
    </>
  );
};
