import type { AvailableSlot, ProviderTypesEnum, TagEnum, VisitLocationEnum, VisitReasonOut } from '@almond/api-types';
import type { AppointmentParamsState } from '~types';

export const isCuratedFlow = (state: AppointmentParamsState, visitReason?: string) => {
  return !state.isNewMemberRemote && visitReason === 'general_infection';
};

export const isMaternityVisitReason = (visitReasons: readonly VisitReasonOut[], visitReason?: string) => {
  return visitReasons.find(vr => vr.code === visitReason)?.category === 'Group Pregnancy Care';
};

export const selectTags = (visitReasonCode: string): TagEnum[] => {
  if (visitReasonCode === 'menopause_intake_session') {
    return ['Menopause Program'];
  }

  if (visitReasonCode === 'establish_virtual_care') {
    return ['Virtual Member'];
  }

  return [];
};

export const isSensitiveVisitReason = (visitReasonCode: string) => {
  return visitReasonCode === 'surgery' || visitReasonCode === 'delivery';
};

export const sortPhysicians = <
  T extends {
    physician: { providerType: ProviderTypesEnum };
    appointmentTypeDetails: { location: VisitLocationEnum };
    timeSlots: readonly AvailableSlot[];
  } & ({ isRecommended: boolean } | { recommendationType: 'primary' | 'secondary' }),
>(
  physicians: readonly T[]
) => {
  const providerOrder: ProviderTypesEnum[][] = [['HC', 'MA', 'MW'], ['NP', 'PA'], ['MD']];
  const locationOrder: VisitLocationEnum[] = ['Video', 'Office'];

  // eslint-disable-next-line max-statements
  return Array.from(physicians).sort((a, b) => {
    const aHasAvailability = a.timeSlots.length > 0 ? 1 : 0;
    const bHasAvailability = b.timeSlots.length > 0 ? 1 : 0;

    if (aHasAvailability !== bHasAvailability) {
      return bHasAvailability - aHasAvailability;
    }

    const aIsRecommended = 'isRecommended' in a && a.isRecommended ? 0 : 1;
    const bIsRecommended = 'isRecommended' in b && b.isRecommended ? 0 : 1;

    if (aIsRecommended !== bIsRecommended) {
      return aIsRecommended - bIsRecommended;
    }

    const aIsPrimary = 'recommendationType' in a && a.recommendationType === 'primary' ? 0 : 1;
    const bIsPrimary = 'recommendationType' in b && b.recommendationType === 'primary' ? 0 : 1;

    // Remove when deleting <SchedulingOld>
    if (aIsPrimary !== bIsPrimary) {
      return aIsPrimary - bIsPrimary;
    }

    const aProviderType = a.physician.providerType;
    const bProviderType = b.physician.providerType;

    const aProviderIndex = providerOrder.findIndex(providers => providers.includes(aProviderType));
    const bProviderIndex = providerOrder.findIndex(providers => providers.includes(bProviderType));

    if (aProviderIndex !== bProviderIndex) {
      return aProviderIndex - bProviderIndex;
    }

    const aLocationIndex = locationOrder.indexOf(a.appointmentTypeDetails.location);
    const bLocationIndex = locationOrder.indexOf(b.appointmentTypeDetails.location);

    return aLocationIndex - bLocationIndex;
  });
};
