import React, { type Dispatch, type SetStateAction } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { useWaitlistModalState } from '~modules/state';

import { footerThemedStyles } from './styles';

import type { FilterState } from '../SchedulingFilters';

export type FooterProps = {
  filters: FilterState;
  setFilters: Dispatch<SetStateAction<FilterState>>;
};

export const Footer = (props: FooterProps) => {
  const { filters, setFilters } = props;
  const [styles] = useTheme(footerThemedStyles);
  const { isMobile } = useBrowserTypeMap();
  const { t } = useTranslation();
  const [state, dispatch] = useWaitlistModalState();

  const handleViewVisitsPress = () => {
    setFilters(prevState => ({
      ...prevState,
      location: filters.location === 'office' ? 'video' : 'office',
    }));
  };

  const handleJoinWaitlistPress = () => {
    dispatch({ type: 'open' });
  };

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      <Text size={isMobile ? 'm' : 'xl'} style={styles.noDateOrTime}>
        {t('schedulingNew.noDateOrTime')}
      </Text>
      <Button size="s" onPress={handleViewVisitsPress}>
        {t(`schedulingNew.${filters.location === 'office' ? 'viewVideoVisits' : 'viewOfficeVisits'}`)}
      </Button>
      {!state.isComplete && (
        <Button size="s" type="secondary" onPress={handleJoinWaitlistPress}>
          {t('schedulingNew.joinWaitlist')}
        </Button>
      )}
    </View>
  );
};
