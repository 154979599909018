import { getShadow, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    backgroundColor: theme.background,
    overflow: 'hidden',
  },

  shadow: {
    ...getShadow(),
  },

  containerDesktop: {
    borderRadius: 20,
    width: 540,
    marginHorizontal: 'auto',
    maxWidth: '100%',
  },

  containerMobile: {
    width: '100%',
  },
}));
