import React from 'react';
import { View } from 'react-native';

import { useTrackAnalyticsEvent } from '@almond/analytics';
import { useTranslation } from '@almond/localization';
import { MaterialIcon, Select, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useLocalSearchParams, useRouter } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { appointmentParamsAtom } from '~modules/state';

import { appointmentUtilities } from '../../services';
import { FilterLoading } from './FilterLoading';

import { individualFilterStyles } from './styles';

import type { VisitReason } from '../../hooks';
import type { NewSelectOptionGroup } from '@almond/ui';
import type { Key } from 'react';

type VisitReasonsFilterProps = {
  isLoading: boolean;
  visitReason: VisitReason | undefined;
  visitReasons: VisitReason[] | undefined;
};

export const VisitReasonFilter = ({ isLoading, visitReason, visitReasons }: VisitReasonsFilterProps) => {
  const { setParams } = useRouter();
  const trackAnalyticsEvent = useTrackAnalyticsEvent();
  const { t } = useTranslation();
  const [styles] = useTheme(individualFilterStyles);
  const { isDesktop } = useBrowserTypeMap();
  const searchParams = useLocalSearchParams();
  const appointmentParamsState = useRecoilValue(appointmentParamsAtom);

  if (!visitReasons || !visitReason || isLoading) {
    return <FilterLoading />;
  }

  const filteredVisitReasons = visitReasons
    .filter(vr => !vr.acuityBookingUrl && vr.isActive)
    .sort((a, b) => a.title.localeCompare(b.title))
    .map(vr => ({
      value: vr.code,
      label: vr.title,
      group: vr.category,
    }));

  const groupedVisitReasons = filteredVisitReasons.reduce(
    (acc, vr) => {
      const group = acc.find(a => a.label === vr.group);

      if (group) {
        group.options.push(vr);
      } else {
        acc.push({
          label: vr.group,
          labelHidden: vr.group === 'general',
          options: [vr],
        });
      }

      return acc;
    },
    [] as NewSelectOptionGroup<(typeof filteredVisitReasons)[number]>[]
  );

  const disableChangingVisitReason = (() => {
    const showAll = searchParams.showAll === 'true';
    const infectionOutcome = searchParams.infectionOutcome === 'true';

    if (searchParams.visit_reason === 'assisted_lab') {
      return !showAll;
    }

    // If we're in the curated flow and the user opts out after filling out the questionnaire,
    // we don't want to allow changing the visit reason
    if (appointmentUtilities.isCuratedFlow(appointmentParamsState, searchParams.visit_reason)) {
      return infectionOutcome;
    }

    if (filteredVisitReasons.length === 1) {
      return true;
    }

    return false;
  })();

  const handleSelectionChange = (reason: Key) => {
    const updatedVisitReason = String(reason);

    setParams({ visit_reason: updatedVisitReason });
    trackAnalyticsEvent('visit_reasons', { visitReasons: updatedVisitReason });
  };

  const triggerContent = (
    <Text size="xl" numberOfLines={1}>
      {isDesktop && `${t('scheduling.visitReasonFilter.book')} `}
      {disableChangingVisitReason ? (
        visitReason.title
      ) : (
        <Text size="xl" fontStyle="bold" style={styles.triggerLabel}>
          {visitReason.title}
        </Text>
      )}
    </Text>
  );

  if (disableChangingVisitReason) {
    return triggerContent;
  }

  return (
    <Select.Root value={visitReason.code} onValueChange={handleSelectionChange}>
      <Select.Trigger testID="VisitReasonFilter-Trigger">
        <View style={styles.trigger}>
          {triggerContent}
          <MaterialIcon source="keyboard-arrow-down" size={24} color="secondaryTextDark" />
        </View>
      </Select.Trigger>
      <Select.OptionContainer options={groupedVisitReasons} testID="VisitReasonFilterPopover" position="popper" />
    </Select.Root>
  );
};
