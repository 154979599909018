import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { BaseButton, ScrollView, SkeletonLoader, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useToggle } from '@almond/utils';

import { JoinWaitlistButton } from './JoinWaitlistButton';
import { Timeslot } from './Timeslot';

import { themedTimeSlotsStyles } from './styles';

import type { AvailableSlot, PhysicianFindOut, VisitLocationEnum } from '@almond/api-types';
import type { Dayjs } from 'dayjs';

export type TimeSlotsProps = {
  selectedDate: Dayjs;
  timeSlots: readonly AvailableSlot[];
  onSelect: (timeSlot: AvailableSlot) => void;
  location: VisitLocationEnum;
  isLoading: false;
  physician: PhysicianFindOut;
};

export type TimesSlotsLoadingProps = Omit<Partial<TimeSlotsProps>, 'isLoading'> & {
  isLoading: true;
};

const DEFAULT_LENGTH = 10;

const LoadingTimeslots: React.FC = () => {
  const [styles] = useTheme(themedTimeSlotsStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const timeSlots = Array.from({ length: DEFAULT_LENGTH }, (_, index) => ({ id: index }));

  return (
    <>
      {isDesktop && (
        <>
          <View style={[styles.timeSlots, styles.timeSlotsDesktop]}>
            {timeSlots.map(item => (
              <SkeletonLoader.RoundedRectangle key={item.id} width={84} height={36} roundness={18} />
            ))}
          </View>
          <View style={styles.footer}>
            <SkeletonLoader.Rectangle width="100%" height={34} />
          </View>
        </>
      )}
      {isMobile && (
        <ScrollView
          showsHorizontalScrollIndicator={false}
          horizontal
          contentContainerStyle={[styles.timeSlots, styles.timeSlotsMobile]}
          scrollEnabled={false}
        >
          {timeSlots.map((_, index) => (
            <SkeletonLoader.RoundedRectangle key={index} width={84} height={36} roundness={18} />
          ))}
        </ScrollView>
      )}
    </>
  );
};

export const Timeslots: React.FC<TimeSlotsProps | TimesSlotsLoadingProps> = props => {
  const [styles] = useTheme(themedTimeSlotsStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const { t } = useTranslation();
  const [isExpanded, toggleIsExpanded] = useToggle(false);

  if (props.isLoading) {
    return <LoadingTimeslots />;
  }

  const { timeSlots, onSelect, location, physician } = props;
  const slicedTimeSlots =
    !isExpanded && timeSlots.length > DEFAULT_LENGTH ? timeSlots.slice(0, DEFAULT_LENGTH) : timeSlots;

  return (
    <>
      {isDesktop && !!timeSlots.length && (
        <>
          <View style={[styles.timeSlots, styles.timeSlotsDesktop]}>
            {slicedTimeSlots.map(item => (
              <Timeslot key={item.key} timeSlot={item} onPress={onSelect} location={location} />
            ))}
          </View>
          <View style={styles.footer}>
            {timeSlots.length <= DEFAULT_LENGTH && <JoinWaitlistButton />}
            {timeSlots.length > DEFAULT_LENGTH && isExpanded && <JoinWaitlistButton />}
            {timeSlots.length > DEFAULT_LENGTH && (
              <BaseButton onPress={toggleIsExpanded} style={styles.showMoreButton}>
                <Text size="m">{t(`schedulingNew.availabilitySection.${isExpanded ? 'hideMore' : 'showMore'}`)}</Text>
              </BaseButton>
            )}
          </View>
        </>
      )}
      {isMobile && !!timeSlots.length && (
        <ScrollView
          showsHorizontalScrollIndicator={false}
          horizontal
          contentContainerStyle={[styles.timeSlots, styles.timeSlotsMobile]}
        >
          {timeSlots.map(timeSlot => (
            <Timeslot key={timeSlot.key} timeSlot={timeSlot} onPress={onSelect} location={location} />
          ))}
          <JoinWaitlistButton isCentered={false} />
        </ScrollView>
      )}
      {!timeSlots.length && (
        <>
          <Text style={styles.noAvailability} size="m" fontStyle="medium">
            {t('schedulingNew.availabilitySection.noAvailability', {
              name: `${physician.firstName} ${physician.lastName}`,
            })}
          </Text>
          <View style={[styles.footer, isMobile && styles.footerMobile]}>
            <JoinWaitlistButton />
          </View>
        </>
      )}
    </>
  );
};
