import React from 'react';
import { View } from 'react-native';

import { useBrowserTypeMap, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { ViewProps } from 'react-native';

export type BackgroundProps = Pick<ViewProps, 'children' | 'accessibilityLabel' | 'style' | 'testID'> & {
  noBackgroundGradient?: boolean;
};

export const Background: React.FC<BackgroundProps> = props => {
  const [styles] = useTheme(themedStyles);
  const { isMobile } = useBrowserTypeMap();
  const { children, accessibilityLabel, style, noBackgroundGradient, testID } = props;

  return (
    <View
      testID={testID}
      aria-label={accessibilityLabel}
      style={[
        styles.container,
        isMobile && styles.containerMobile,
        !noBackgroundGradient && styles.containerDesktop,
        style,
      ]}
    >
      {children}
    </View>
  );
};
