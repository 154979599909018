import React from 'react';

import { useTranslation } from '@almond/localization';
import { Link, Text, useTheme } from '@almond/ui';

import { ErrorMessage } from '~modules/errors';
import { useArtemisRedirectUrl } from '~modules/integration';
import { InterstitialLayout } from '~modules/layout';

import themedStyles from './styles';

import type { VisitReasonOut } from '@almond/api-types';

export type NewMemberVisitReasonsNewProps = {
  isLoading: boolean;
  visitReasons: VisitReasonOut[] | undefined;
  onSubmit: (item: string) => Promise<void>;
  statusBar: React.ReactNode;
};

export const NewMemberVisitReasonsNew = (props: NewMemberVisitReasonsNewProps) => {
  const { isLoading, visitReasons, onSubmit, statusBar } = props;
  const { t } = useTranslation();
  const artemisRedirectUrl = useArtemisRedirectUrl();
  const [styles] = useTheme(themedStyles);
  const establishCareVisitReason = visitReasons?.find(
    visitReason => visitReason.code === 'establish_care' || visitReason.code === 'establish_virtual_care'
  );

  if (!isLoading && !establishCareVisitReason) {
    return (
      <ErrorMessage
        error={new Error('establish_care/establish_virtual_care visit reason was not returned from the backend.')}
      />
    );
  }

  return (
    <InterstitialLayout
      id="visitReasons"
      title={t('visitReasons.title')}
      description={<Text size="l">{t('visitReasons.description')}</Text>}
      submitButtonProps={{
        children: t('visitReasons.submitTitle'),
        onPress: establishCareVisitReason ? () => onSubmit(establishCareVisitReason.code) : undefined,
        isDisabled: !establishCareVisitReason,
      }}
      showImage={false}
      footer={
        <Link url={artemisRedirectUrl} size="m" style={styles.returningMember}>
          {t('visitReasons.returningMember')}
        </Link>
      }
      isLoading={isLoading}
      statusBar={statusBar}
    />
  );
};
