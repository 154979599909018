import React, { useMemo } from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { ActivityIndicator, MaterialIcon, PickerPopover, Text, useTheme, VisitReasonIcon } from '@almond/ui';

import themedStyles from './styles';

import type { VisitReasonOut } from '@almond/api-types';
import type { NewSelectOptionGroup } from '@almond/ui';

type VisitReasonsProps = {
  isLoading: boolean;
  visitReasons: VisitReasonOut[] | undefined;
  onSubmit: (item: string) => Promise<void>;
};

export const VisitReasons = (props: VisitReasonsProps) => {
  const { isLoading, visitReasons, onSubmit } = props;
  const [styles] = useTheme(themedStyles);
  const { t } = useTranslation();

  const visitReasonOptions = useMemo(() => {
    return visitReasons?.map(visitReason => ({
      label: visitReason.title,
      value: visitReason.code,
      group: visitReason.category,
      acuityBookingUrl: visitReason.acuityBookingUrl,
    }));
  }, [visitReasons]);
  const visitReasonOptionGroups = useMemo(() => {
    return visitReasonOptions?.reduce(
      (acc, vr) => {
        const group = acc.find(a => a.label === vr.group);

        if (group) {
          group.options.push(vr);
        } else {
          acc.push({
            label: vr.group,
            labelHidden: vr.group === 'general',
            options: [vr],
          });
        }

        return acc;
      },
      [] as NewSelectOptionGroup<(typeof visitReasonOptions)[number]>[]
    );
  }, [visitReasonOptions]);

  return (
    <View style={styles.topAlign}>
      <View style={styles.visitReasonPickerContainer}>
        <Text fontStyle="medium" size="xl" style={styles.visitReasonPickerTitle}>
          {t('visitReasons.getCare')}
        </Text>
        {isLoading || !visitReasonOptions || !visitReasonOptionGroups ? (
          <ActivityIndicator />
        ) : (
          <PickerPopover.Root>
            <PickerPopover.Trigger>
              <View style={styles.visitReasonPicker}>
                <VisitReasonIcon
                  visitReason={visitReasonOptions[0].value}
                  backgroundColor="artemisVisitReasonBackground"
                />
                <Text fontStyle="medium" size="xl">
                  {t('visitReasons.choose')}
                </Text>
                <MaterialIcon source="keyboard-arrow-down" size={24} color="secondaryTextDark" />
              </View>
            </PickerPopover.Trigger>
            <PickerPopover.Popover<(typeof visitReasonOptions)[number]>
              options={visitReasonOptionGroups}
              renderItem={({ option, wrapperClassName, textInlinePadding, textProps, setIsOpen }) => (
                <button
                  className={wrapperClassName}
                  onClick={() => {
                    setIsOpen(false);
                    onSubmit(option.value);
                  }}
                  data-option-value={option.value}
                >
                  <div className={textInlinePadding}>
                    <Text {...textProps}>{option.label}</Text>
                  </div>
                </button>
              )}
            />
          </PickerPopover.Root>
        )}
      </View>
    </View>
  );
};
