import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { MaterialIcon, Select, Text, useTheme } from '@almond/ui';

import { isVirtualMembershipProduct, useStripeMembership } from '~modules/product';

import { useVisitReasons } from '../../hooks';
import { FilterLoading } from './FilterLoading';

import { individualFilterStyles } from './styles';

import type { FilterState } from './types';
import type { Dispatch, SetStateAction } from 'react';

export type LocationFilterProps = {
  filters: FilterState;
  setFilters: Dispatch<SetStateAction<FilterState>>;
};

export const LocationFilter = ({ filters, setFilters }: LocationFilterProps) => {
  const { t } = useTranslation();
  const [styles] = useTheme(individualFilterStyles);
  const membershipType = useStripeMembership();
  const { visitReason } = useVisitReasons();

  if (!filters.location) {
    return <FilterLoading />;
  }

  const visitLocationLocked =
    visitReason?.visitOutcome &&
    'visitLocationLocked' in visitReason.visitOutcome &&
    visitReason.visitOutcome.visitLocationLocked;

  const locationOptions = [
    { value: 'video' as const, label: t('scheduling.locationFilter.video') },
    { value: 'office' as const, label: t('scheduling.locationFilter.office') },
  ];

  if (isVirtualMembershipProduct(membershipType)) {
    return (
      <Text size="xl" numberOfLines={1}>
        {`${t('scheduling.locationFilter.showing')} ${t(`scheduling.locationFilter.${filters.location}Label`)}`}
      </Text>
    );
  }

  if (visitLocationLocked) {
    return (
      <Text size="xl" numberOfLines={1}>
        {t('scheduling.locationFilter.requires', { context: filters.location })}
      </Text>
    );
  }

  return (
    <Select.Root
      value={filters.location}
      onValueChange={location => {
        setFilters(prev => ({
          ...prev,
          location,
        }));
      }}
    >
      <Select.Trigger testID="LocationFilterTrigger">
        <View style={styles.trigger}>
          <Text size="xl" numberOfLines={1}>
            {`${t('scheduling.locationFilter.showing')} `}
            <Text size="xl" fontStyle="bold" style={styles.triggerLabel}>
              {t(`scheduling.locationFilter.${filters.location}Label`)}
            </Text>
          </Text>
          <MaterialIcon source="keyboard-arrow-down" size={24} color="secondaryTextDark" />
        </View>
      </Select.Trigger>
      <Select.OptionContainer options={locationOptions} position="popper" align="end" />
    </Select.Root>
  );
};
