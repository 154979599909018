{
    "abandonModal": {
        "title": "Anything we can do to improve?",
        "subtitle": "Your feedback helps us know what is most important to focus on.",
        "newMemberOptions": {
            "noProviders": "Not seeing providers I want to see",
            "noDays": "Can’t find days/visits that work",
            "notSure": "Not sure about cost",
            "somethingElse": "Something else"
        },
        "establishedMemberOptions": {
            "noProviders": "Not seeing providers I want to see",
            "noDays": "Can’t find days/visits that work",
            "contactUs": "Message care team"
        }
    },
    "activateMembershipButtonTitle": "Activate Membership",
    "additionalOfferModal": {
        "title": "Get an additional {{ price }} off",
        "toastText": "OFFER35 promo applied at check out!"
    },
    "address": {
        "title": "What is your address?",
        "city": "City",
        "line1": "Address",
        "line2": "Apartment, Suite, etc.",
        "stateCode": "State Code"
    },
    "addressRequiredError": "Please select a pharmacy from the dropdown above or message your care team if you can't find your pharmacy",
    "allergies": {
        "title": "Do you have any other allergies?",
        "placeholder": "Please list any allergies here, this information will help our team ensure we have the whole picture.",
        "skipTitle": "I don’t have any other allergies"
    },
    "and": "and",
    "appError": {
        "title": "Oops looks like our wires got crossed",
        "message": {
            "title": "Let’s try that again! Let us know if the problem is persisting."
        },
        "retry": "Retry",
        "getHelp": "Get Help"
    },
    "backToWebsite": "Back to website",
    "bookAppointment": {
        "title": "",
        "officeTitle": "Last thing - confirm your office visit",
        "videoTitle": "Last thing - confirm your video visit",
        "appointmentTime": "Appointment Time",
        "name": "Name",
        "provider": "Provider",
        "location": "Location",
        "payment": "Payment",
        "submitOfficeTitle": "Confirm office visit",
        "submitVideoTitle": "Confirm video visit",
        "bottom": "Almond does not bill you for any labs, prescriptions, or imaging done outside of our office.",
        "newMemberDisclaimer": "👋 Please be aware this is a new member visit and will last 30 minutes. Procedures such as IUD or Nexplanon insertion require consultations.",
        "newMemberVirtualDisclaimer": "👋 Please be aware this is a new member visit and will last 30 minutes."
    },
    "bookGeneralVisit": "Book a General Visit instead",
    "bookSomethingElse": "Book something else",
    "cashRates": {
        "title": "We Keep Our Rates Clear"
    },
    "chooseOne": "Choose one:",
    "classicMembershipRenewal": "Almond Classic Membership Renewal",
    "closeButton": {
        "modal": {
            "title": "Are you sure you want to exit?",
            "subtitle": "By exiting this window you will lose your current booking progress.",
            "cancelButton": "Continue my booking",
            "leaveButton": "Exit & lose progress"
        }
    },
    "confirmEmail": {
        "message": {
            "title": "Confirm your email",
            "subtitle": "We need to confirm your email to complete your account set up! Go to your email and click the “{{ buttonTitle }}” button.",
            "baselineEmailButton": "complete health history",
            "gynecologyEmailButton": "finish account setup"
        }
    },
    "confirmPurchase": {
        "title": "Last thing - confirm your purchase!",
        "serviceName": "Service",
        "labCosts": " + lab costs",
        "payment": "Payment",
        "total": "Total",
        "submitTitle": "Confirm purchase",
        "bottom": "You can choose whether to pay directly or use your insurance for labs."
    },
    "confirmationModal": {
        "title": "Thank you!",
        "subtitle": "We may reach out if you shared your contact information."
    },
    "consentSignature": {
        "title": "Consent",
        "subtitle": {
            "text1": "Sign below to agree to Almond’s ",
            "link1": "Terms of Service and Consent to Treat",
            "text2": ", and to acknowledge our ",
            "link2": "Notice of Privacy Practices",
            "text3": ", and ",
            "link3": "Privacy Policy",
            "text4": ", and to agree to be contacted by Almond regarding my appointment, results, and related content."
        },
        "caption": "Use your cursor to sign in the box",
        "submitTitle": "I Consent",
        "clear": "Clear"
    },
    "contactUs": {
        "callOrTextUs": "Call or text us at "
    },
    "contactUsModal": {
        "title": "We can help",
        "subtitle": "Have a question? Call or text 7am-7pm any day of the week.",
        "callUs": "Call 424-237-8406",
        "textUs": "Text 424-237-8406"
    },
    "continue": "Continue",
    "createPatient": {
        "title": "We're getting you set up",
        "subtitle": "Almost there—a few more steps to confirm your visit! 📆"
    },
    "creditCard": {
        "title": "Billing Information",
        "firstMonthFreeSubtitle": "Get your first month of membership free — nothing charged today",
        "skipTitle": "I will add my credit card later",
        "promotion": {
            "discount": "Discount",
            "referralDiscount": "Referral Credit",
            "total": "Total",
            "apply": "Apply",
            "label": "Discount Code",
            "removeCode": "Remove discount code {{code}}",
            "renewal": "Membership will renew annually at {{price}}",
            "not-found": "\"{{code}}\" isn't one of our codes :(",
            "not-applicable": "\"{{code}}\" isn't valid for this membership :(",
            "hideSummary": "Hide Order Summary",
            "showSummary": "Show Order Summary"
        },
        "totalExplanation": "We believe you will love Almond so much that we offer a full refund of your purchase within 30 days of sign up if you aren’t completely satisfied.",
        "totalExplanationFirstMonthFree": "Nothing will be charged today. Membership will be charged in 30 days. We believe you will love Almond so much that we are offering you your first month of membership free. ❤️",
        "termsText": "By providing your card information, you allow Almond ObGyn to charge your card for future payments in accordance with our terms."
    },
    "dailyAvailability": {
        "title": {
            "default": "Choose a date and time for your visit",
            "labwork": "Choose a date and time to get labwork",
            "swab": "Choose a date and time to come in and self-swab"
        },
        "noAvailability": "Please select another date, There are no available times this day. Select a visit time.",
        "noProviderAvailability": "There are no available times for this provider.",
        "scrollDown": "Scroll down to view more appointments"
    },
    "defaultError": "We’ve run into an issue booking your appointment. Please call us at 424-237-8406 and we’ll help you out by phone!",
    "demographic": {
        "title": "Let’s get your contact info!",
        "birthday": "Birth Date",
        "cell": "Cell",
        "email": "Email",
        "firstName": "First Name",
        "preferredFirstName": "Preferred First Name (optional)",
        "lastName": "Last Name",
        "required": "required field",
        "error": {
            "part1": "It looks like you may already have an account with us, please ",
            "signIn": "sign in",
            "part2": " to your account.",
            "contactCareTeam": "It looks like you may already have an account with us, please contact your care team at 424-237-8406 to get help.",
            "phone": "phone number",
            "email": "email address",
            "adminPart1": "That {{ identifier }} is already associated with an existing member account. ",
            "adminPart2": "Click here",
            "adminPart3": " to start a booking under the existing account."
        },
        "optInLabel": {
            "mainText": "I'd like to receive and send text messages directly with my Almond Care Team about sensitive medical and billing information.{{ spacer }}By checking this box you accept Almond’s ",
            "privacyPolicy": "privacy policy",
            "and": "and",
            "termsOfService": "terms of service"
        }
    },
    "dismiss": "Dismiss",
    "doctorInfo": {
        "title": "Who is your ObGyn",
        "placeholder": "Doctor’s last name, city, state",
        "subtitle": "Getting this information before your visit gives your care provider the best information with which to craft your care plan.",
        "skipTitle": "I don’t have an ObGyn"
    },
    "done": "Done",
    "drugAllergies": {
        "title": "Do you have any drug allergies?",
        "placeholder": "Please list any drug allergies here, this information will help our team ensure we have the whole picture.",
        "skipTitle": "I don’t have any drug allergies"
    },
    "error": {
        "timeout": "Hm. The site is having an issue. Please try again or call us at 424-237-8406 and we’ll help you out by phone <3"
    },
    "errorBoundary": {
        "title": "Something went wrong"
    },
    "familyHistory": {
        "title": "Any family history of medical conditions?",
        "placeholder": "If yes, please describe conditions in your immediate family and grandparents here.",
        "skipTitle": "There are no conditions I’m aware of"
    },
    "feedbackModal": {
        "title": "Anything we can do to improve?",
        "phoneLabel": "Cell phone number (optional)",
        "emailLabel": "Email (optional)",
        "feedbackPlaceholder": "Your feedback goes directly to our Care Team (we really do read it all!).",
        "submitTitle": "Send",
        "phoneInvalid": "Please enter a valid 10 digit phone number",
        "feedbackInvalid": "Please enter a feedback."
    },
    "finishAccountSetup": {
        "title": "Finish account set up",
        "baselineTitle": "Welcome to Baseline!",
        "subtitle": "Your visit is confirmed but we have a quick question to finish your account set up.",
        "baselineSubtitle": "Your membership is confirmed but we have a quick question to finish your account set up."
    },
    "firstMonthFreeModal": {
        "title": "Get your first month for free!",
        "toastText": "FIRSTMONTHFREE promo applied at check out!"
    },
    "formError": "Please correct the fields above",
    "getTestedInstead": "Get tested instead",
    "healthConditions": {
        "title": "Let’s talk about your general health history",
        "placeholder": "Do you have any health conditions? Understanding your history will help us take better care of you."
    },
    "historyInterstitial": {
        "title": "Health History Questionnaire",
        "description": "Your health history gives your Care Team important info about you that they need in order to be prepared for your visit and offer you the best care possible.",
        "submit": "Get started!"
    },
    "hospitalization": {
        "title": "Have you ever been hospitalized?",
        "placeholder": "Please list any non-pregnancy hospitalizations here.",
        "skipTitle": "I have never been hospitalized"
    },
    "idleModal": {
        "title": "Looking for something?",
        "subtitle": "How can we help?",
        "newMemberOptions": {
            "noProviders": "Not seeing providers I want to see",
            "noDays": "Can’t find days/visits that work",
            "notSure": "Not sure about cost",
            "notReady": "Not ready to book",
            "somethingElse": "Something else",
            "contactUs": "Talk to care team"
        },
        "establishedMemberOptions": {
            "noProviders": "Not seeing providers I want to see",
            "noDays": "Can’t find days/visits that work",
            "notReady": "Not ready to book",
            "somethingElse": "Something else",
            "contactUs": "Message care team"
        }
    },
    "infectionOutcome": {
        "title": "Here’s what happens next...",
        "reviewing": {
            "subtitle": "We are reviewing your responses",
            "body": [
                "A member of your Care Team will review your responses and get back to you within 4 business hours. They may reach out with additional questions, or as a next step recommend a medication or a visit.",
                "If you have any other questions, please message or call us. We're here to help and want to make sure you get the best care."
            ]
        },
        "urgentCare": {
            "subtitle": "We recommend you consider urgent care",
            "body": {
                "part1": "A member of your Care Team will review your answers and get back to your within 4 business hours. However anytime you have:",
                "part2": [
                    "a fever over 100.4 degrees",
                    "back or flank (side) pain: pain in your back just under your rib cage",
                    "are experiencing UTI symptoms for more than 5 days",
                    "been exposed to an STI such as gonorrhea, chlamydia, trichomoniasis, hepatitis B, hepatitis C, herpes simplex, HIV, syphilis or HPV"
                ],
                "part3": "We recommend you go to urgent care to receive immediate medical attention.",
                "part4": "If you need help finding an urgent care center or if you have any other questions, message us or give us a call at (424) 237-8406. If you’re planning to use insurance, we recommend you check to make sure the urgent care you choose accepts your insurance."
            }
        }
    },
    "input": {
        "placeholder": "Type here..."
    },
    "insurance": {
        "title": "Select your health insurance",
        "noInsurance": "I’m not using health insurance",
        "addCarrierName": "Add insurance carrier name"
    },
    "insuranceId": {
        "title": "Let’s get your insurance information",
        "label": "ID Number (Optional)",
        "card": {
            "back": "Back of Card (Optional)",
            "front": "Front of Card"
        },
        "secondaryTitle": "Add later"
    },
    "insuranceNotAccepted": {
        "title": "Unfortunately, we don’t accept your insurance yet"
    },
    "intakeFormIntro": {
        "title": "The {{ productName }} Intake",
        "msqTitle": "The {{ productName }} Symptoms Survey",
        "description": "The {{ productName }} Intake gives your provider the information they need to understand where you are in your journey so they can provide you with personalized treatment options.",
        "msqDescription": "The Symptoms Survey is a few questions you'll answer before each visit with your clinician. It helps us track your symptoms and the effectiveness of specific treatments.",
        "submit": "Start Intake",
        "msqSubmit": "Start Survey",
        "cancel": "Back to dashboard"
    },
    "invalidRequestException": "There was a problem with the request. Please try again or contact our support.",
    "labOrders": {
        "title_one": "Would you like to book a lab visit for this lab order?",
        "title_other": "Would you like to book a lab visit for these lab orders?",
        "submit": "Yes, book lab visit",
        "messageCareTeam": "No, message Care Team",
        "orderedBy": "Ordered by: {{ provider }}",
        "view": "View",
        "itemTitle": "{{ orderingDate }} Lab Order",
        "noneTitle": "Looks like you don’t have any active lab orders",
        "noneSubtitle": "What does this mean?",
        "noneDescription": "To know which lab tests to draw we need a lab order created by your provider within the last 60 days. If you think we got this wrong or if you want to request a lab order please send us a message or call us.",
        "noneMessageCareTeam": "Message Care Team"
    },
    "learnMore": {
        "title": "What's on your mind? We're here for it!",
        "call": "Questions? Call or text us at 424-237-8406",
        "schedule": "We know you’re busy—schedule a time to chat",
        "copySuccess": "Phone number copied! 📲"
    },
    "login": {
        "title": "Login to your account",
        "submitTitle": "Sign In"
    },
    "maps": {
        "getDirections": "Get directions",
        "error": {
            "generic": "There was an error loading the map",
            "noResults": "No results",
            "invalidZip": "Please enter a valid zip code"
        }
    },
    "medications": {
        "title": "Are you taking any medications?",
        "placeholder": "Please list any medications here, this information will help our team ensure we have the whole picture.",
        "skipTitle": "I’m not taking any medications"
    },
    "messageCareTeam": "Message care team",
    "navigation": {
        "title": "Book Care"
    },
    "newPatientVisit": "New Patient Visit",
    "nextSteps": {
        "title": "Next Steps",
        "generic": {
            "subtitle": {
                "part1": "At your visit we'll review your medical history, health goals and address any immediate concerns. Please complete any To Dos related to your upcoming visit 24 hours prior.\n\nHelp us keep things flowing smoothly and ",
                "part2": "arrive {{ minutes }} before your visit time",
                "part3": ". If life throws you a plot twist, kindly give us at least 24 hours notice to ",
                "part4": "cancel or reschedule",
                "part5": " to avoid being charged.🙏"
            }
        },
        "baseline": {
            "subtitle": {
                "part1": "Look out for a lab order to schedule your lab testing within 24 hours. You’ll receive this via text if you are ",
                "part2": "opted-into",
                "part3": " receiving texts from Almond and/or as a message in your dashboard.\n\nIf you have any questions don’t hesitate to reach out to our team!"
            }
        },
        "submit": "Take Me Home",
        "secondary": "Message Care Team"
    },
    "no": "No",
    "noStates": "No states were found.",
    "noneOfThese": "None of these",
    "notFoundException": "Couldn’t find the requested item.",
    "notRemember": "I don’t remember",
    "notSureAboutCostModal": {
        "subtitle": "Sign up for Almond membership today and get your first month of membership free — nothing is charged for 30 days.",
        "joinAlmondFor": "Join Almond for ~{{ oldPrice }}~ {{ newPrice }} first year",
        "equivalentTo": "Equivalent to {{ price }}/mo, less than a nice meal out!",
        "bottomText": "If you don’t love Almond, just let us know within 30 days of your visit and we’ll refund you the membership fee."
    },
    "occupation": {
        "title": "What is your occupation?",
        "placeholder": "This helps your provider better understand your lifestyle."
    },
    "other": "Other",
    "perniciousHabits": {
        "title": "Please note your usage of non-prescription substances below",
        "daily": "Daily",
        "monthly": "Monthly",
        "weekly": "Weekly",
        "yearly": "Yearly",
        "never": "Never",
        "other": "Other"
    },
    "pharmacy": {
        "title": "Confirm your preferred pharmacy",
        "placeholder": "Search here...",
        "alt": "Search for a pharmacy",
        "helperText": "<0>Search for Alto Pharmacy</0> to get your medication delivered. We have no formal affiliation we just love their fast delivery, good prices and excellent service.",
        "empty": "No pharmacy found"
    },
    "phoneVerification": {
        "title": "Let’s verify your phone number",
        "titleMobile": "Let’s verify your\nphone number",
        "subtitle": "Enter the code sent to",
        "submitTitle": "Submit",
        "codeNotReceived": "Didn't receive a code?",
        "codeSent": "New code sent."
    },
    "pregnancies": {
        "title": "Have you ever been pregnant?",
        "abortion": "Abortion",
        "birth": "Birth",
        "miscarriage": "Miscarriage",
        "outcome": "Outcome",
        "year": "Year",
        "skipTitle": "No, I have not been pregnant"
    },
    "previousScreen": "Previous Screen",
    "pricingExplanation": {
        "resubscribeBanner": "⏳ Time to renew your membership! Call or text us if you have questions 424-237-8406",
        "joinCommunity": "JOIN THE COMMUNITY",
        "learnMore": "I want to learn more about Almond 🙋🏾‍♀️",
        "limitedPromo": "Limited time offer: get your first month of membership free",
        "baseline": {
            "title": "Get Baseline for ",
            "subtitle": "80+ tests with a personalized analysis from our team of functional medicine providers. Know where you stand so you can act early to optimize your health.",
            "subscriptionItemsTitle": "What's included",
            "submit": "Get Baseline",
            "learnMore": "I want to learn more about Baseline 🙋🏾‍♀️",
            "subscriptionItems": [
                [
                    "Test the 80+ most essential biomarkers at any Labcorp location for",
                    " less than half ",
                    "of what it costs from your typical primary care provider. Cost of lab tests is included in price."
                ],
                "Get personalized analysis and insights from our team of functional medicine providers.",
                "Ask any questions in a 15 minute video visit over Zoom with our Functional Health Coach."
            ]
        },
        "content": {
            "annual": "ANNUAL",
            "perYear": "/year",
            "firstYear": "first year",
            "disclaimerFirstMonthFree": "Membership fee will be charged 30 days after your sign up date. Cancel anytime before with no charge.",
            "disclaimer": "You may cancel within 30 days of your sign up date for any reason."
        },
        "virtualMembership": {
            "title": "The Virtual Membership",
            "subtitle": "Get access over video or texting to top integrative providers and a highly skilled care team who specializes in women’s health",
            "subscriptionItemsTitle": "Included with membership:",
            "subscriptionItems": [
                "Anytime online booking for video visits (including weekends!)",
                "Access to top integrative practitioners specialized in women’s health",
                "Unlimited text messaging with your Care Team",
                "Comprehensive, integrative lab testing",
                "Get quick care for things that don’t require a visit, like birth control refill requests",
                "Second opinion from an integrative medicine provider",
                "Access to exclusive programs as an add-on to membership like health coaching, deep dive PCOS care, menopause and perimenopause care, and midwife consultations"
            ]
        },
        "gynecologyMembership": {
            "title": "Join the Almond Community",
            "subtitle": "Moving beyond treating symptoms to solving problems.",
            "subscriptionItemsTitle": "What does membership include?",
            "subscriptionItems": [
                "Full-scope women’s healthcare",
                "Anytime online booking",
                "Same-day video visits",
                "Weekend and evening visits",
                "Personalized care plans after every visit",
                "Unlimited texting directly with your providers",
                "Easy prescription refills",
                "Best-in-class integrative practitioner",
                "Easy, comfortable, and flexible on-site lab work",
                "More comprehensive testing"
            ]
        },
        "sidebarContent": {
            "title": "How Almond Works",
            "description": "We charge a membership fee so we can provide you with extra special care. You may cancel within 30 days of your first visit for any reason and receive a full refund of your membership fee. Visit costs are charged separately.",
            "firstMonthFreeDescription": "We charge a membership fee so we can provide you with extra special care. The full Almond membership fee is charged 30 days after sign up and renewed annually. Visit costs are charged separately.",
            "withInsurance": {
                "label": "With Insurance",
                "title": "We accept most California PPO plans: United Healthcare, Cigna, Blue Shield and Anthem Blue Cross.\n\nAlmond does not determine costs of prescriptions, labs, and imaging; these are billed to your insurance. If you are responsible for a copay, co-insurance or a deductible we will charge your card on file after your visit and send you a receipt via email.\n\nWe will always work with you to keep your healthcare costs down and recommend the lowest cost options."
            },
            "withoutInsurance": {
                "label": "Without Insurance",
                "title": {
                    "header": "We keep costs clear. Our most common visit prices:\n\n",
                    "prices": [
                        "$300: office visit",
                        "$200: video visit",
                        "$250: same-day video visit",
                        "$155: Ultrasound",
                        "$155: IUD Removal"
                    ],
                    "footer": "\nAdditional costs may be added depending on your visit focus\n\nAlmond does not determine costs of prescriptions, labs or, imaging. We will always work with you to keep your healthcare costs down and recommend the lowest cost options.\n\nGive us a call or text us for more details :)"
                }
            }
        }
    },
    "primaryCareProvider": {
        "title": "Who is your primary care provider?",
        "placeholder": "Full name of provider",
        "skipTitle": "Skip"
    },
    "promotionCodes": {
        "referral": {
            "success": "You have a great friend! Your {{ amountOff }} off referral code will be applied to your membership fee. 💜",
            "not-found": "Oops that referral code is not valid.",
            "not-applicable": "Oops that referral code does not apply to this membership.",
            "returning": "Referral codes can only be used by new members."
        },
        "promotion": {
            "firstmonthfree": "Try Almond for free for 30 days. Limited time only, discount applied at checkout. 💜",
            "success": "Treat yourself to good treatment. Discount code {{ code }} applied at checkout.",
            "not-found": "Sorry, promotion code {{ code }} is invalid or has expired.",
            "not-applicable": "Sorry, promotion code {{ code }} does not apply to this membership.",
            "returning": "Promotion codes can only be used by new members."
        }
    },
    "provider": {
        "title": "Choose a Provider",
        "noProviders": "We’re sorry, no providers are available right now"
    },
    "ratesMessage": {
        "title": "Common out-of-pocket visit costs",
        "officeVisit": "Office Visit: ",
        "telehealthVisit": "Telehealth Visit: ",
        "sameDayVisit": "Same-day Telehealth Visit: ",
        "ultrasound": "Ultrasound: ",
        "bottom": "Prescription, lab, and imaging pricing is determined by outside of Almond."
    },
    "recommendation": {
        "title": "Our Recommendation",
        "curated": {
            "primary": "Sounds Good",
            "secondary": "Book Visit Right Away",
            "body": "We need to ask you a few quick questions to help us decide if we should recommend a prescription, testing, or a visit."
        },
        "primary": "Continue with Recommendation",
        "secondary": "Show me all visit availability",
        "subtitle": "{{ location }} with {{ provider }}",
        "anyProvider": "Any Provider",
        "copy": {
            "officeProvider_np": "For {{ visitReason }} we recommend you see a Nurse Practitioner (NP) or a Physician Assistant (PA).",
            "officeProvider_md": "For {{ visitReason }} we recommend you see an ObGyn.",
            "officeProvider_hc": "For {{ visitReason }} we recommend an office visit with a Health Coach.",
            "videoProvider_np": "For {{ visitReason }} we recommend a video visit with a Nurse Practitioner (NP) or a Physician Assistant (PA).",
            "videoProvider_md": "For {{ visitReason }} we recommend a video visit with an ObGyn.",
            "videoProvider_hc": "For {{ visitReason }} we recommend a video visit with a Health Coach.",
            "anyLocationProvider_np": "For {{ visitReason }} we recommend a visit with a Nurse Practitioner (NP) or a Physician Assistant (PA).",
            "anyLocationProvider_md": "For {{ visitReason }} we recommend a visit with an ObGyn.",
            "anyLocationProvider_hc": "For {{ visitReason }} we recommend a visit with a Health Coach.",
            "officeNoProvider": "For {{ visitReason }} we require an office visit for a physical exam. This type of visit usually lasts {{ duration }} minutes.",
            "videoNoProvider": "For {{ visitReason }} we recommend a video visit. We'll spend about {{ duration }} minutes together for this visit.",
            "videoNoProvider_first": "For {{ visitReason }} we recommend a video visit. We'll spend about {{ duration }} minutes together for your first visit.",
            "anyLocationNoProvider": "For {{ visitReason }} you can choose a video or office visit. This type of visit usually lasts {{ duration }} minutes.",
            "officeVisit": "These visits require an office visit for a physical exam and usually last about {{ duration }} minutes.",
            "videoVisit": "We'll spend about {{ duration }} minutes together for this visit.",
            "videoVisit_first": "We'll spend about {{ duration }} minutes together for your first visit.",
            "summary": "This recommendation enables us to get you the best care without long wait times. You’re in good hands with our well-rounded care team no matter who you see."
        },
        "provider": {
            "pa": "Women’s Health Physician Assistant",
            "np": "Women’s Health Nurse Practitioner",
            "ma": "Women’s Health Medical Assistant",
            "md": "Board-Certified Obstetrician & Gynecologist",
            "mdIfm": "Board-Certified Obstetrician & Gynecologist, IFM-Trained"
        }
    },
    "referral": {
        "title": "How did you find Almond?",
        "referralTitle": "Tell us how you heard about us (friend, doctor, Yelp, Google Maps, somebody you follow on Instagram):",
        "referralPlaceholder": "How did you hear about us?",
        "referrerByNameTitle": "We'd love to thank who referred you:",
        "referrerByNamePlaceholder": "Full name of referrer",
        "finishTitle": "Finish"
    },
    "resendCode": "Resend code",
    "resendCodeIn": "Resend another code\nin {{ timeout }} seconds",
    "scheduling": {
        "text": "Scheduling",
        "waitlistTitle": "Not seeing the time you want?",
        "waitlistComplete": "You’re on the waitlist! Book a back up visit below.",
        "waitlistButton": "Join the Waitlist",
        "secondaryRecommendationAllLocations": "See all visit availability",
        "secondaryRecommendationObgyn": "See all ObGyn visit availability",
        "secondaryRecommendationOfficeLocation": "See all office visit availability",
        "availabilitySection": {
            "experience": "Experience: ",
            "education": "Education: ",
            "readLess": "Read less",
            "readMore": "Read more",
            "recommended": "Recommended",
            "durationMinutes": "minutes",
            "showMore": "Show more visits",
            "hideMore": "Hide more visits",
            "noAvailability": "No availability on {{ selectedDate }}",
            "goToNextDate": "Go to first available {{ location }} visit",
            "nextAvailableVisit_one": "First available {{ location }} visit is tomorrow",
            "nextAvailableVisit_other": "First available {{ location }} visit in {{ count }} days",
            "nextAvailableVisitDate": "First available {{ location }} visit on {{ date }}",
            "noAvailableContact": "Contact the Care Team to book the next {{ location }} appointment with this provider",
            "talkToCareTeam": "Talk to Care Team"
        },
        "waitlistModal": {
            "title": "Join the Waitlist",
            "subtitle": "Let us know what works for you and we’ll text you if we get an opening. You can book a back up visit after this too if you'd like.",
            "fields": {
                "appointmentReason": "Visit Reason",
                "fullName": "Full Name",
                "location": "Location",
                "phoneNumber": "Cell Phone",
                "availabilityText": "What days/times would work best for you?"
            },
            "availabilityTextHelper": "For example, next Wednesday between 7am-11am or Fridays before 3pm",
            "completeTitle": "You’re on the waitlist!",
            "completeSubtitle": "If we get an opening we’ll text you. Would you like to book a back up visit in the meantime?",
            "completeAndMembershipSubtitle": "If we get an opening we’ll text you. Would you like to book a back up visit and sign up for Almond membership in the meantime?",
            "completeButton": "Book a Back Up Visit",
            "completeButtonNotOnScheduling": "Finish booking"
        },
        "mobileInfoHelperText": "See more information on your visit type",
        "disclaimer": {
            "title": "We want to make sure this is the right visit for you",
            "message": "Our recommendation is designed to get you in for care ASAP with the right provider in the right location. You know best what you need, so we understand if you prefer a different option.",
            "submitTitle": "Back to all visit options",
            "secondaryTitle": "Continue with my choice"
        },
        "providerFilter": {
            "error": "Error loading providers",
            "selectAtLeastOneProvider": "Please select at least one provider",
            "requiresObGyn": "Requires ObGyn",
            "showing": "Showing",
            "allProviders": "all providers",
            "nProviders_one": "{{count}} provider",
            "nProviders_other": "{{count}} providers"
        },
        "visitReasonFilter": {
            "book": "Book visit for"
        },
        "locationFilter": {
            "showing": "Showing",
            "video": "Video",
            "office": "Office",
            "videoLabel": "video visits",
            "officeLabel": "office visits",
            "requires_video": "Requires a video visit",
            "requires_office": "Requires an office visit"
        },
        "curated": "We need to ask you a few quick questions to help us decide if we should recommend a prescription, testing, or a visit."
    },
    "schedulingNew": {
        "availabilitySection": {
            "videoVisit": "Video visit over Zoom",
            "officeVisit": "Office visit",
            "subtitleTemplate": "{{visitType}} | {{duration}} min",
            "experience": "Experience: ",
            "education": "Education: ",
            "readBio": "Read Bio",
            "showMore": "Show more times",
            "hideMore": "Show fewer times",
            "noAvailability": "{{name}} is all booked up on this day!",
            "recommended": "🍓 Our recommendation based on your needs"
        },
        "viewOfficeVisits": "View office visits instead",
        "viewVideoVisits": "View video visits instead",
        "noDateOrTime": "Not seeing dates or times that work?",
        "joinWaitlist": "Join the Waitlist",
        "waitlistJoined": "You’re on the waitlist! 🎉",
        "jumpToDatePicker": {
            "jumpTo": "Jump to",
            "date": "date"
        }
    },
    "scrollDown": "Scroll down to view more",
    "searchByAvailability": "Search by Availability",
    "seeAvailableVisitTimes": "See Available Visit Times",
    "selectAll": "Select all that apply",
    "selectInput": {
        "title": "Select one option",
        "placeholder": "Select one"
    },
    "serverErrorException": "Something went wrong. Please try again or contact our support.",
    "settings": "Settings",
    "signOut": "Sign Out",
    "somethingElse": "Something else",
    "states": "States",
    "surgeries": {
        "title": "Have you had any surgeries or procedures?",
        "placeholder": "Please list any here, this information will help our team ensure we have the whole picture.",
        "skipTitle": "I have not had any surgeries or procedures"
    },
    "symptoms": {
        "title": "What other symptoms are you having?"
    },
    "takeMeHome": "Take Me Home",
    "tellUsMore": {
        "title": "What’s been going on?",
        "messageCareTeam": "Message Care Team",
        "newMember": {
            "header": "👋 In your first visit we’ll spend time going over your medical history, health goals and address any immediate concerns.",
            "placeholder": "Tell us what you would like to address in this first visit.\n\nIf you’re looking for lab work, imaging (i.e. ultrasounds) or device insertions (i.e. IUDs) those things require specific visits so please let us know and we can help you book a follow-up visit. :)"
        },
        "establishedMember": {
            "header": "👋 For lab work please message our Care Team directly to schedule.",
            "placeholder": "Share your expectations for this visit and any questions you have for your provider.\n\nWe like to be prepared for your visit so you don’t just get seen, but you feel seen too."
        }
    },
    "typeformResponse": {
        "errorText": "To complete your booking, please call us at 424-237-8406 and let us know that you filled out the infection questionnaire."
    },
    "unknownException": "Something went wrong. Please try again or contact our support.",
    "validations": {
        "dateFormatError": "Please enter a valid date {{format}}",
        "dateMinError": "Date can’t be before {{minDate}}",
        "dateMaxError": "Date must be in the past",
        "emailFormatError": "Please enter a valid email address",
        "nameInvalidCharacter": "Names cannot contain the characters (){}[],|",
        "phoneFormatError": "Please enter a valid phone number (10 digits)",
        "stateCodeFormatError": "Please enter a valid state",
        "zipFormatError": "Please enter a valid zip code",
        "imageError": "Please upload an image",
        "otpError": "Please enter a valid 6 digits verification code"
    },
    "virtualMembership": {
        "modal": {
            "title": "Are you currently located in California?",
            "subtitle": "We can only provide care to patients who are currently located in California at the time of care."
        }
    },
    "visitCategories": {
        "title": "What type of care are you looking for?",
        "categories": {
            "general": {
                "title": "Gynecology Care",
                "subtitle": "Almond Membership means we’re here for you—from Birth Control to UTIs to Hormones and more."
            },
            "Group Pregnancy Care": {
                "title": "Maternity Care",
                "subtitle": "Your membership includes fertility, hormonal and preconception care. Explore our add-on classes too!"
            },
            "PCOS": {
                "title": "The PCOS Program",
                "subtitle": "Book a PCOS program or health coaching visit for a PCOS member."
            },
            "Health Coaching": {
                "title": "Health Coaching",
                "subtitle": "As as add-on to membership schedule a 1:1 session with one of our in-house health coaches for extra support."
            },
            "Baseline": {
                "title": "Baseline",
                "subtitle": "Comprehensive lab testing designed for women and with insights from expert MDs."
            },
            "Menopause": {
                "title": "The Menopause Program",
                "subtitle": "Sign a member up for The Menopause program and schedule their intake session."
            }
        }
    },
    "visitCostMessage": {
        "withoutInsuranceTitle": "Visit Fees without Insurance",
        "withoutInsuranceDescription": "You can absolutely still join Almond without using insurance—we're committed to keeping all costs clear.",
        "secondaryMessage": {
            "title": "Estimated out-of-pocket visit costs:",
            "officeVisit": "Office visit: ",
            "telehealthVisit": "Telehealth visit: ",
            "sameDayVisit": "Same-day telehealth visit: ",
            "ultrasound": "Ultrasound: "
        },
        "bottom": "Almond does not determine costs of prescriptions, labs, or imaging."
    },
    "visitQuestions": {
        "title": "Anything else you want to us to be aware of?",
        "placeholder": "Think about this as your mental checklist for your appointment and include any questions. Your provider will review before your appointment."
    },
    "visitReason": "Visit Reason",
    "visitReasons": {
        "categories": {
            "general": "General Care Options",
            "Group Pregnancy Care": "Maternity Care Options",
            "PCOS": "The PCOS Program",
            "Health Coaching": "Health Coaching",
            "Baseline": "Baseline",
            "Menopause": "The Menopause Program"
        },
        "subcategories": {
            "general": "The Basics",
            "Group Pregnancy Care": "Maternity Care Options",
            "PCOS": "The PCOS Program",
            "Health Coaching": "Health Coaching",
            "Baseline": "Baseline",
            "Menopause": "The Menopause Program"
        },
        "title": "Let's get you taken care of",
        "pickOne": "Choose a visit reason:",
        "returningMember": "I’m already an Almond member 👋",
        "items": {
            "assisted_lab": {
                "title": "Labwork",
                "inSentence": "labwork"
            }
        },
        "description": "Our philosophy at Almond is to start by going deep. At your first visit we'll focus on understanding your medical history, learning about your health goals, and addressing any immediate concerns.\n\nWe recommend a video visit to save you parking time, but would also love to welcome you to the office if you prefer to meet in-person or if you want a physical exam. ❤️",
        "submitTitle": "Find a time",
        "getCare": "Get Care",
        "choose": "Choose a visit reason"
    },
    "visitType": {
        "officeVisit": "Office Visit",
        "videoVisit": "Video Visit",
        "anyLocationVisit": "Visit"
    },
    "yes": "Yes",
    "yourCarePlan": "Your Care Plan",
    "zip": "Zip",
    "signIn": "Sign In"
}
