import { StyleSheet } from 'react-native';

import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    paddingHorizontal: 12,
  },

  containerMobile: {
    backgroundColor: 'transparent',
  },

  filter: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'center',
    height: 48,
    paddingHorizontal: 4,
  },

  filterDesktop: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.borderLight,
  },

  filterIcon: {
    flexShrink: 0,
  },
}));

export const individualFilterStyles = styleSheetFactory(theme => ({
  error: {
    color: theme.error,
  },

  trigger: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  triggerLabel: {
    color: theme.secondaryTextDark,
  },
}));
