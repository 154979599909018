import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const schedulingThemedStyles = styleSheetFactory(theme => ({
  containerDesktop: {
    rowGap: 16,
    paddingVertical: 24,
  },

  containerMobile: {
    rowGap: 12,
  },

  availabilitySectionContainerDesktop: {
    rowGap: 16,
  },

  infoIcon: {
    paddingHorizontal: sizes.SPACING_S,
  },

  errorDisabled: {
    opacity: 0.4,
  },

  disclaimerContainer: {
    backgroundColor: theme.lightAccent,
    padding: sizes.SPACING_M,
    marginHorizontal: 'auto',
  },
  disclaimerText: {
    textAlign: 'center',
  },
}));

export const footerThemedStyles = styleSheetFactory(theme => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 540,
    maxWidth: '100%',
    marginHorizontal: 'auto',
    rowGap: 10,
    padding: 10,
  },

  containerMobile: {
    width: '100%',
  },

  noDateOrTime: {
    color: theme.darkText,
  },
}));

export const calendarThemedStyles = styleSheetFactory(theme => ({
  triggerContainer: {
    flexDirection: 'row',
    width: 340,
  },

  trigger: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  triggerLabel: {
    color: theme.secondaryTextDark,
  },

  popover: {
    ...getShadow(true),
    borderRadius: sizes.SPACING_M,
    backgroundColor: theme.background,
  },

  bottomSheetContent: {
    flex: 1,
    backgroundColor: theme.background,
    marginTop: sizes.SPACING_L,
    borderTopStartRadius: sizes.SPACING_M,
    borderTopEndRadius: sizes.SPACING_M,
  },

  close: {
    alignSelf: 'flex-end',
    margin: sizes.SPACING_S,
  },
}));
