import { useMemo } from 'react';

import { dateAndTimeParsers } from '@almond/date-and-time';
import dayjs from 'dayjs';

import { dailyAvailabilityApi, MULTIDAY_AVAILABILITY_PATH, useFocusedSWR } from '~modules/api';
import { dataTransformsUtilities } from '~modules/dataTransforms';

import { MAX_CALENDAR_BAR_DAYS_VISIBLE } from '../config';

import type { GetMultidayAvailabilityParams } from '@almond/api-types';
import type { Dayjs } from 'dayjs';

const TODAY = dayjs();

export type MultidayParams = Omit<GetMultidayAvailabilityParams, 'start_date' | 'number_of_days'>;

/**
 * Take a date of interest, and get the aligned start_date for the API request
 * that will include the date of interest
 * @param startDate The date the UI is interested in
 * @param alignmentDate The date to align API requests to
 * @param numberOfDays The number of days requested by each API request
 * @returns The startDate of the API request that will include startDate data
 */
const getAlignedDate = (startDate: Dayjs, alignmentDate: Dayjs, numberOfDays: number) => {
  const daysInFuture =
    Math.floor(startDate.startOf('day').diff(alignmentDate.startOf('day'), 'days') / numberOfDays) * numberOfDays;

  return alignmentDate.add(daysInFuture, 'days');
};

/**
 * Get the SWR key for a request starting at a given startDate (pre-aligned)
 */
const getKey = (
  startDate: string | null | undefined,
  numberOfDays: number,
  params: MultidayParams | null | undefined
) => {
  if (startDate == null || params == null) {
    return null;
  }

  const copiedParams: GetMultidayAvailabilityParams = {
    ...params,
    number_of_days: numberOfDays,
    start_date: startDate,
  };

  return [MULTIDAY_AVAILABILITY_PATH, copiedParams] as const;
};

/**
 * Take the SWR key and make the request for multiday availability.
 * Fill in any missing days
 * Complexity may be able to be reduced with https://github.com/almondobgyn/backend/pull/511 merged
 */
export const fetcher = async ([, params]: [string, GetMultidayAvailabilityParams]) => {
  const numberOfDays = params.number_of_days;

  if (typeof numberOfDays !== 'number') {
    throw new Error('Must specify number_of_days');
  }

  const paramsWithUndefinedStrippedOut = dataTransformsUtilities.omitNullishFields(params);

  const data = await dailyAvailabilityApi.getMultidayAvailability(paramsWithUndefinedStrippedOut);

  if (data.physiciansAvailability.length === numberOfDays) {
    return data.physiciansAvailability;
  }

  // Ensure every day has an entry, even if it's empty
  // The API should guarantee this, but <CalendarBar> breaks if this
  // requirement isn't met, so let's just keep this code for now.
  const dataMap = Object.fromEntries(data.physiciansAvailability.map(a => [a.day, a]));
  const startDate = dayjs(params.start_date);

  const availability: Array<(typeof data.physiciansAvailability)[number]> = [];

  for (let i = 0; i < numberOfDays; i += 1) {
    const date = dateAndTimeParsers.toRemoteDate(startDate.add(i, 'days'));

    availability.push(dataMap[date] || { day: date, physiciansTimeSlots: [] });
  }

  return availability;
};

/**
 * This hook fetches and returns `numberOfDays` worth of availability data,
 * starting at `startDate`.
 * Because we are fetching and caching requests starting at `alignmentDate`,
 * we want to make sure we make API requests starting at `alignmentDate`,
 * and moving forwards in whole intervals of `numberOfDays`.
 * For example, if numberOfDays=10 and alignmentDate=2012-02-03, we will
 * only make requests for 2012-02-03, 2012-02-13, 2012-02-23 (intervals
 * of 10 days), regardless of how `startDate` aligns. If `startDate` is
 * 2012-02-08, the hook will request 2012-02-03 and 2012-02-13, and combine
 * the results to return.
 * @param startDate The first day that the UI cares about rendering
 * @param params The parameters to send to the API
 * @param numberOfDays The number of days each request is for, to align API requests
 * @param alignmentDate A constant day that is used to align API requests
 * @returns List of appointment times
 */
export const useSchedulingMultiday = (
  startDate: Dayjs | null,
  params: MultidayParams | null,
  numberOfDays = MAX_CALENDAR_BAR_DAYS_VISIBLE,
  alignmentDate = TODAY
) => {
  const firstRequestDate = useMemo(
    () => startDate && dateAndTimeParsers.toRemoteDate(getAlignedDate(startDate, alignmentDate, numberOfDays)),
    [startDate, alignmentDate, numberOfDays]
  );
  const response = useFocusedSWR(getKey(firstRequestDate, numberOfDays, params), fetcher, {
    shouldRetryOnError: false,
  });

  return useMemo(() => {
    return {
      data: response.data,
      error: response.error,
      isLoading: response.isLoading,
      retry: response.mutate,
    };
  }, [response]);
};
