import React from 'react';

import { Text, useTheme } from '@almond/ui';

import { useVisitReasons } from '../../hooks';
import { SchedulingSection } from '../SchedulingSection';

import { schedulingThemedStyles } from './styles';

export const SchedulingDisclaimer = () => {
  const { visitReason } = useVisitReasons();
  const visitOutcome = visitReason?.visitOutcome;
  const [styles] = useTheme(schedulingThemedStyles);

  if (!visitOutcome || !('disclaimerCopy' in visitOutcome) || !visitOutcome.disclaimerCopy) {
    return null;
  }

  return (
    <SchedulingSection style={styles.disclaimerContainer}>
      <Text size="m" style={styles.disclaimerText}>
        {visitOutcome.disclaimerCopy}
      </Text>
    </SchedulingSection>
  );
};
