import React from 'react';
import { View } from 'react-native';

import { PulseProvider, useBrowserTypeMap, useTheme } from '@almond/ui';

import { SchedulingSection } from '../SchedulingSection';
import { Physician } from './Physician';
import { RecommendedBanner } from './RecommendedBanner.tsx';
import { Timeslots } from './Timeslots';

import { themedContainerStyles } from './styles';

import type { AppointmentTypeDetails, AvailableSlot, PhysicianFindOut } from '@almond/api-types';
import type { Dayjs } from 'dayjs';

export type AvailabilitySectionProps = {
  physician: PhysicianFindOut;
  appointmentDetails: AppointmentTypeDetails;
  selectedDate: Dayjs;
  timeSlots: readonly AvailableSlot[];
  onTimeSlotSelect: (timeSlot: AvailableSlot) => void;
  isLast: boolean;
  // TODO: Implement it later
  isRecommended?: boolean;
};

export type AvailabilitySectionLoadingProps = { isLast?: boolean };

export const AvailabilitySectionLoading = ({ isLast }: AvailabilitySectionLoadingProps) => {
  const [styles] = useTheme(themedContainerStyles);
  const { isMobile } = useBrowserTypeMap();

  return (
    <PulseProvider duration={1000}>
      <SchedulingSection style={!isLast && isMobile && styles.bottomBorder}>
        <View style={styles.container}>
          <Physician isLoading />
          <Timeslots isLoading />
        </View>
      </SchedulingSection>
    </PulseProvider>
  );
};

export const AvailabilitySectionNew = (props: AvailabilitySectionProps) => {
  const [styles] = useTheme(themedContainerStyles);
  const { isMobile } = useBrowserTypeMap();

  const { physician, appointmentDetails, selectedDate, timeSlots, onTimeSlotSelect, isRecommended, isLast } = props;

  return (
    <SchedulingSection
      style={[
        isRecommended && styles.recommendedContainer,
        isRecommended && isMobile && styles.recommendedContainerMobile,
        !isRecommended && !isLast && isMobile && styles.bottomBorder,
      ]}
    >
      {isRecommended && <RecommendedBanner />}
      <View
        style={styles.container}
        testID="SchedulingAvailabilitySection"
        // dataSet isn't in react-native type definitions, but it's supported in react-native-web.
        // https://github.com/necolas/react-native-web/releases/tag/0.13.0
        // @ts-expect-error
        dataSet={{ physician: physician.uuid, appointmentType: appointmentDetails.appointmentTypeUuid }}
      >
        <Physician
          physician={physician}
          duration={appointmentDetails.duration}
          location={appointmentDetails.location}
          isLoading={false}
        />
        <Timeslots
          selectedDate={selectedDate}
          timeSlots={timeSlots}
          onSelect={onTimeSlotSelect}
          location={appointmentDetails.location}
          isLoading={false}
          physician={physician}
        />
      </View>
    </SchedulingSection>
  );
};
