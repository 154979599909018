import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { FiltersIcon, useBrowserTypeMap, useScrollViewRef } from '@almond/ui';
import clsx from 'clsx';

import { NAVIGATION_BAR_MOBILE_HEIGHT } from '~modules/integration';

import styles from './MobileFilterScrollIcon.module.css';

import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import type { View } from 'react-native';

type MobileFilterScrollIconContextType = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  onClick: () => void;
  setOnClick: Dispatch<SetStateAction<() => void>>;
};

const MobileFilterScrollIconContext = createContext<null | MobileFilterScrollIconContextType>(null);

export const MobileFilterScrollIcon = (props: PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(false);
  const [onClick, setOnClick] = useState<() => void>(() => {});

  const contextValue: MobileFilterScrollIconContextType = useMemo(
    () => ({
      isVisible,
      setIsVisible,
      onClick,
      setOnClick,
    }),
    [isVisible, onClick, setIsVisible, setOnClick]
  );

  return (
    <MobileFilterScrollIconContext.Provider value={contextValue}>
      <button
        // No value to screen readers or keyboard users
        aria-hidden
        tabIndex={-1}
        className={clsx(styles.scrollIcon, isVisible && styles.visible)}
        style={{
          // @ts-expect-error
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '--navigation-bar-mobile-height': `${NAVIGATION_BAR_MOBILE_HEIGHT}px`,
        }}
        onClick={onClick}
      >
        <FiltersIcon />
      </button>
      {props.children}
    </MobileFilterScrollIconContext.Provider>
  );
};

export const useMobileFilterScrollIcon = (buttonRef: React.RefObject<View>) => {
  const context = useContext(MobileFilterScrollIconContext);
  const scrollViewRef = useScrollViewRef();
  const { isDesktop } = useBrowserTypeMap();

  const root: unknown = scrollViewRef.current;

  if (!context) {
    throw new Error('useMobileFilterScrollIcon must be used within a MobileFilterScrollIcon');
  }

  if (root !== null && !(root instanceof HTMLElement)) {
    throw new Error('Must be used within React DOM environment');
  }

  const { setIsVisible, setOnClick } = context;

  // Hide the icon on desktop
  useEffect(() => {
    if (isDesktop) {
      setIsVisible(false);
    }
  }, [isDesktop, setIsVisible]);
  useEffect(() => {
    // Must be a function that returns a function
    // because useState assumes a function returns a value
    setOnClick(() => () => {
      root?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }, [setOnClick, root]);

  useEffect(() => {
    if (!buttonRef || isDesktop) {
      return;
    }

    const ele: unknown = buttonRef.current;

    if (!(ele instanceof HTMLElement)) {
      return;
    }

    const THRESHOLD = 0.2;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio < THRESHOLD) {
          // Element is less than 20% visible
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        threshold: [THRESHOLD],
        root,
      }
    );

    observer.observe(ele);

    return () => {
      observer.unobserve(ele);
    };
  }, [buttonRef, isDesktop, setIsVisible, root]);
};
