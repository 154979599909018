/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useRef } from 'react';
import { View } from 'react-native';

import {
  FemaleDoctorIcon,
  getVisitReasonIcon,
  MaterialIcon,
  PulseProvider,
  useBrowserTypeMap,
  useTheme,
} from '@almond/ui';

import { Calendar } from '~assets';

import { useVisitReasons } from '../../hooks';
import { SchedulingSection } from '../SchedulingSection';
import { LocationFilter } from './LocationFilter';
import { useMobileFilterScrollIcon } from './MobileFilterScrollIcon';
import { ProviderFilter } from './ProviderFilter';
import { VisitReasonFilter } from './VisitReasonFilter';

import { themedStyles } from './styles';

import type { FilterState } from './types';
import type { Dispatch, SetStateAction } from 'react';

type FiltersProps = {
  filters: FilterState;
  setFilters: Dispatch<SetStateAction<FilterState>>;
  onlyShowVisitReason?: boolean;
  jumpTo?: React.ReactNode;
};
export const SchedulingFilters = ({ filters, setFilters, onlyShowVisitReason, jumpTo }: FiltersProps) => {
  const [styles] = useTheme(themedStyles);
  const { visitReasons, visitReason, isLoadingVisitReasons } = useVisitReasons();
  const { isMobile } = useBrowserTypeMap();
  const ref = useRef<View>(null);

  useMobileFilterScrollIcon(ref);

  const Icon = getVisitReasonIcon(visitReason?.code ?? '');

  return (
    <PulseProvider>
      <View ref={ref}>
        <SchedulingSection style={[styles.container, isMobile && styles.containerMobile]} noShadow={isMobile}>
          <Filter icon={<Icon width={20} />}>
            <VisitReasonFilter
              isLoading={isLoadingVisitReasons}
              visitReason={visitReason}
              visitReasons={visitReasons}
            />
          </Filter>
          {!onlyShowVisitReason && (
            <>
              <Filter icon={<MaterialIcon source="camera" size={20} color="secondaryTextDark" />}>
                <LocationFilter filters={filters} setFilters={setFilters} />
              </Filter>
              <Filter icon={<FemaleDoctorIcon width={20} />}>
                <ProviderFilter filters={filters} setFilters={setFilters} />
              </Filter>
              <Filter icon={<Calendar width={20} />} isLast>
                {jumpTo}
              </Filter>
            </>
          )}
        </SchedulingSection>
      </View>
    </PulseProvider>
  );
};

type FilterProps = {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  isLast?: boolean;
};

const Filter = ({ icon, children, isLast }: FilterProps) => {
  const [styles] = useTheme(themedStyles);
  const { isDesktop } = useBrowserTypeMap();

  return (
    <View style={[styles.filter, isDesktop && !isLast && styles.filterDesktop]}>
      <View style={styles.filterIcon}>{icon}</View>
      {children}
    </View>
  );
};
