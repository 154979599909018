/* eslint-disable max-lines */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';

import { dateAndTimeParsers } from '@almond/date-and-time';
import { ScrollView, useBrowserTypeMap, useTheme } from '@almond/ui';
import dayjs from 'dayjs';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { ErrorMessage } from '~modules/errors';
import { isVirtualMembershipProduct, useStripeMembership } from '~modules/product';
import { appointmentParamsAtom, patientAtom } from '~modules/state';
import { CalendarBar } from '~modules/ui';

import { MAX_CALENDAR_BAR_DAYS_VISIBLE } from '../../config';
import { useGetProvidersForCurrentVisitReason } from '../../hooks';
import { logError } from '../../logger';
import { appointmentUtilities } from '../../services';
import { AvailabilitySectionLoading, AvailabilitySectionNew } from '../AvailabilitySectionNew';
import { DisclaimerModal } from '../DisclaimerModal';
import { InfectionInterstitial } from '../InfectionInterstitial';
import { SchedulingFilters } from '../SchedulingFilters';
import { SchedulingSection } from '../SchedulingSection';
import { TODAY } from './config';
import { Footer } from './Footer';
import { JumpToDatePicker } from './JumpToDatePicker';
import { SchedulingDisclaimer } from './SchedulingDisclaimer';
import { useMonthlyRequest } from './useMonthlyRequest';
import { useScrollToSelectedAppointmentType } from './useScrollToSelectedAppointmentType';
import { useSelectTime } from './useSelectTime';
import { useSetInitialDate } from './useSetInitialDate';
import { useSingleDayData } from './useSingleDayData';

import { schedulingThemedStyles } from './styles';

import type { VisitReason } from '../../hooks';
import type { FilterState } from '../SchedulingFilters';
import type { CalendarBarInstance } from '~modules/ui';
import type { Dayjs } from 'dayjs';
import type { ScrollView as ScrollViewType } from 'react-native';

type SchedulingNewProps = {
  visitReason: VisitReason | undefined;
};

// eslint-disable-next-line max-statements
export const SchedulingNew = (props: SchedulingNewProps) => {
  const [styles] = useTheme(schedulingThemedStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();
  const appointmentParamsState = useRecoilValue(appointmentParamsAtom);
  const isNewMemberRemote = !!appointmentParamsState.isNewMemberRemote;
  const patientState = useRecoilValue(patientAtom);
  const calendarBarRef = useRef<CalendarBarInstance>(null);
  const scrollViewRef = useRef<ScrollViewType>(null);
  const selectedProviderRef = useRef<{ physicianUuid: string; appointmentType: string } | null>(null);
  const visitOutcome = props.visitReason?.visitOutcome;

  const membershipType = useStripeMembership();
  const searchParams = useLocalSearchParams();
  const [filters, setFilters] = useState<FilterState>({
    location: null,
    providers: [],
  });

  useEffect(() => {
    if (visitOutcome) {
      setFilters(prev => ({ ...prev, location: visitOutcome.isTelehealth ? 'video' : 'office' }));
    } else {
      setFilters(prev => ({ ...prev, location: null }));
    }
  }, [visitOutcome]);

  const isCuratedFlow =
    appointmentUtilities.isCuratedFlow(appointmentParamsState, searchParams.visit_reason) &&
    searchParams.infectionOutcome !== 'true';

  const params = useMemo(() => {
    if (!props.visitReason || !visitOutcome) {
      return null;
    }

    return {
      is_new_member: isNewMemberRemote,
      is_telehealth: isVirtualMembershipProduct(membershipType) || filters.location === 'video',
      provider_types: visitOutcome.providerTypes ?? undefined,
      visit_reason: props.visitReason.code,
    };
  }, [props.visitReason, visitOutcome, isNewMemberRemote, membershipType, filters.location]);
  const physiciansResponse = useGetProvidersForCurrentVisitReason(filters.location);
  const {
    availableDates,
    lastLoadedDate: lastLoadedMonthlyDate,
    requestDataAt: requestMonthlyDataAt,
    error: monthlyError,
    retry: monthlyRetry,
  } = useMonthlyRequest(params, filters.providers);
  // The date the user has selected, for which time slots are
  // being displayed
  const [selectedDate, setSelectedDate] = useState(TODAY);
  const {
    error: singleDayError,
    retry: singleDayRetry,
    data: singleDayData,
    isLoading: isPrimaryLoading,
  } = useSingleDayData(selectedDate, params, filters.providers, visitOutcome);
  const { onSelectTime, onSubmit, isDisclaimerModalVisible, toggleIsDisclaimerModalVisible } = useSelectTime(
    visitOutcome,
    singleDayData
  );

  const selectDateAndScrollToIt = (date: Dayjs, physicianUuid?: string, appointmentType?: string) => {
    setSelectedDate(date);
    calendarBarRef.current?.scrollToDate(date);
    if (physicianUuid && appointmentType) {
      selectedProviderRef.current = { physicianUuid, appointmentType };
    }
  };

  useSetInitialDate(availableDates, selectDateAndScrollToIt);

  const visitDurations = Array.from(
    new Set(
      (singleDayData || []).flatMap(physicianTimeSlots => {
        return physicianTimeSlots.appointmentTypeDetails.duration;
      })
    )
  );

  // Ensure we're alerted if there's more than one visit duration. Should never happen
  useEffect(() => {
    if (visitDurations.length > 1) {
      logError(new Error(`Expected 1 visit duration but received ${visitDurations.length}.`), JSON.stringify(params));
    }
  }, [visitDurations.length, params]);

  const isLoading = physiciansResponse.isLoading || isPrimaryLoading || !props.visitReason;

  useScrollToSelectedAppointmentType({ isLoading, singleDayData, selectedProviderRef, selectedDate, scrollViewRef });

  const notSeenInLastYear = useMemo(
    () =>
      !patientState.lastSeenTimestamp || dayjs(patientState.lastSeenTimestamp).isBefore(dayjs().subtract(1, 'year')),
    [patientState.lastSeenTimestamp]
  );

  const monthlyDateStatus = useCallback(
    (date: Dayjs) => {
      if (date.isAfter(lastLoadedMonthlyDate)) {
        return null;
      }

      return !!availableDates?.[dateAndTimeParsers.toRemoteDate(date)];
    },
    [availableDates, lastLoadedMonthlyDate]
  );

  const monthsOffset = isMobile ? 3 : 1;

  const [error, retry] = (() => {
    if (singleDayError) {
      return [singleDayError, singleDayRetry] as const;
    }

    if (monthlyError) {
      return [monthlyError, monthlyRetry] as const;
    }

    if (physiciansResponse.error) {
      return [physiciansResponse.error, physiciansResponse.retry] as const;
    }

    return [null, () => {}] as const;
  })();

  return (
    <>
      <ScrollView
        listenToNativeEvents
        ref={scrollViewRef}
        testID="ContainerScrollView"
        contentContainerStyle={isDesktop ? styles.containerDesktop : styles.containerMobile}
      >
        <SchedulingDisclaimer />
        <SchedulingFilters
          filters={filters}
          setFilters={setFilters}
          onlyShowVisitReason={isCuratedFlow}
          jumpTo={
            <JumpToDatePicker
              onDateSelect={date => {
                setSelectedDate(date);
                calendarBarRef.current?.scrollToDate(date);
              }}
              selectedDate={selectedDate}
              numVisible={monthsOffset}
              availableDates={availableDates}
              requestDataAt={requestMonthlyDataAt}
            />
          }
        />
        {isCuratedFlow ? (
          <InfectionInterstitial />
        ) : (
          <>
            <SchedulingSection>
              <CalendarBar
                isAllLoading={!lastLoadedMonthlyDate}
                ref={calendarBarRef}
                selectedDate={selectedDate}
                onSelectDate={date => setSelectedDate(date)}
                onVisibleDateChange={(_, end) => {
                  requestMonthlyDataAt(end);
                }}
                dateStatus={monthlyDateStatus}
                visibleDays={MAX_CALENDAR_BAR_DAYS_VISIBLE}
              />
            </SchedulingSection>

            <View style={isDesktop && styles.availabilitySectionContainerDesktop}>
              {error && (
                <SchedulingSection>
                  <ErrorMessage error={error} onTryAgain={retry} isFull={false} />
                </SchedulingSection>
              )}
              {(!singleDayData || isLoading) && !error && (
                <>
                  <AvailabilitySectionLoading />
                  <AvailabilitySectionLoading isLast />
                </>
              )}
              {singleDayData &&
                !isLoading &&
                !error &&
                singleDayData.map((physicianTimeSlots, index) => {
                  const physician = physiciansResponse.physicians?.find(
                    p => p.uuid === physicianTimeSlots.physician.uuid
                  );

                  if (!physician) return null;
                  if (!physicianTimeSlots.timeSlots?.length) {
                    // If this provider has no availability today

                    // Hide the card if it's a returning member (who has
                    // had an appt within the last year)
                    if (!isNewMemberRemote && !notSeenInLastYear) return null;
                    // If it's a new member (or hasn't been in >1yr), show them
                    // "Next available" card, but only for MDs (hide for PA/NP/etc)
                    if (physician.providerType !== 'MD') return null;
                  }

                  const appointmentDetails = physicianTimeSlots.appointmentTypeDetails;

                  return (
                    <AvailabilitySectionNew
                      selectedDate={selectedDate}
                      onTimeSlotSelect={visitTime => {
                        return onSelectTime(
                          visitTime,
                          physician,
                          appointmentDetails.appointmentTypeUuid,
                          appointmentDetails.location
                        );
                      }}
                      key={`${physicianTimeSlots.physician.uuid} ${appointmentDetails.location}`}
                      timeSlots={physicianTimeSlots.timeSlots}
                      appointmentDetails={appointmentDetails}
                      physician={physician}
                      isRecommended={physicianTimeSlots.isRecommended}
                      isLast={index === singleDayData.length - 1}
                    />
                  );
                })}
            </View>
            <Footer filters={filters} setFilters={setFilters} />
          </>
        )}
      </ScrollView>
      {visitOutcome && (
        <DisclaimerModal
          isVisible={isDisclaimerModalVisible}
          onRequestClose={toggleIsDisclaimerModalVisible}
          visitOutcome={visitOutcome}
          onSubmit={() => {
            toggleIsDisclaimerModalVisible();
            onSubmit();
          }}
        />
      )}
    </>
  );
};
