import React, { useEffect, useState } from 'react';

import { useTranslation } from '@almond/localization';
import { getStylesForWeb, MaterialIcon, MultiSelect, Text, useTheme } from '@almond/ui';
import { useGate } from 'statsig-react';

import { useGetProvidersForCurrentVisitReason } from '../../hooks';
import { FilterLoading } from './FilterLoading';

import { individualFilterStyles } from './styles';

import type { FilterState } from './types';
import type { PhysicianFindOut } from '@almond/api-types';
import type { MultiSelectOption } from '@almond/ui';
import type { Dispatch, SetStateAction } from 'react';

const allProviders = { isChecked: false, value: '_', isAllProviders: true as const, label: 'All Providers' };

type AllProvidersOption = typeof allProviders;
type ProviderOption = MultiSelectOption<string> & { physician: PhysicianFindOut };
type ProviderOptionWithAllOption = ProviderOption | AllProvidersOption;
const isAllProviders = (option: MultiSelectOption<string> | AllProvidersOption): option is AllProvidersOption =>
  'isAllProviders' in option && option.isAllProviders;

const getNewOptions = (options: ProviderOptionWithAllOption[], option: ProviderOptionWithAllOption) => {
  if (isAllProviders(option)) {
    if (!option.isChecked) {
      return options;
    }

    return options.map(o => {
      return isAllProviders(o) ? option : { ...o, isChecked: false };
    });
  }

  const newOpts = options.map(o => (o.value === option.value ? option : o));
  const allChecked = newOpts.every(o => o.isChecked || isAllProviders(o));
  const noneChecked = newOpts.every(o => !o.isChecked);

  if (allChecked) {
    return options.map(o => ({ ...o, isChecked: isAllProviders(o) }));
  }

  if (noneChecked) {
    return options;
  }

  return newOpts.map(o => (isAllProviders(o) ? { ...o, isChecked: false } : o));
};

// Alex and I discussed the behavior. She is advocating for the `getNewOptions` behavior, but I'm
// not sure it's correct. I wouldn't be surprised if someone decides they want different behavior,
// so I also implemented the alternate behavior. We can quickly toggle it on if anyone wants to debate
// different behavior.
const getNewOptionsAlternate = (options: ProviderOptionWithAllOption[], option: ProviderOptionWithAllOption) => {
  if (isAllProviders(option)) {
    return options.map(o => ({ ...o, isChecked: option.isChecked }));
  }

  const newOpts = options.map(o => (o.value === option.value ? option : o));
  const allChecked = newOpts.every(o => o.isChecked || isAllProviders(o));

  return newOpts.map(o => (isAllProviders(o) ? { ...o, isChecked: allChecked } : o));
};

type ProviderFilterProps = {
  filters: FilterState;
  setFilters: Dispatch<SetStateAction<FilterState>>;
};

export const ProviderFilter = ({ filters, setFilters }: ProviderFilterProps) => {
  const allProvidersBehaviorGate = useGate('scheduling_allproviders_behavior');
  const { physicians, isLoading, error /* mutate */ } = useGetProvidersForCurrentVisitReason(filters.location);
  const [options, setOptions] = useState<ProviderOptionWithAllOption[]>([]);
  const [styles] = useTheme(individualFilterStyles);
  const { t } = useTranslation();

  useEffect(() => {
    if (!physicians) return;

    const opts = physicians.map(p => ({
      value: p.uuid,
      label: `${p.firstName} ${p.lastName}, ${p.title}`,
      isChecked: allProvidersBehaviorGate.value,
      physician: p,
    }));

    if (physicians.length > 1) {
      setOptions([{ ...allProviders, isChecked: true }, ...opts]);
    } else {
      setOptions(opts.map(o => ({ ...o, isChecked: true })));
    }
  }, [physicians, allProvidersBehaviorGate.value]);

  // Ideally, we de-duplicate this state. We don't need to store the selected options in the `options` array
  // and also the `filters` passed in as a prop. But because of how the "All Providers" option is implemented,
  // we need to store that in local state.
  useEffect(() => {
    const isAllChecked = options.find(isAllProviders)?.isChecked;

    setFilters(prev => ({
      ...prev,
      providers: options
        .filter((o): o is ProviderOption => !isAllProviders(o) && (isAllChecked || o.isChecked))
        .map(o => o.physician),
    }));
  }, [options, setFilters]);

  const onToggle = (option: ProviderOptionWithAllOption) => {
    setOptions(opts =>
      allProvidersBehaviorGate.value ? getNewOptionsAlternate(opts, option) : getNewOptions(opts, option)
    );
  };

  const areAllProvidersObGyn = physicians?.every(p => p.providerType === 'MD') ?? false;

  const selectedCount = options.some(o => isAllProviders(o) && o.isChecked)
    ? options.length
    : options.filter(o => !isAllProviders(o) && o.isChecked).length;

  const [staticLabel, triggerLabel] = (() => {
    if (areAllProvidersObGyn) {
      return [t('scheduling.providerFilter.requiresObGyn')];
    }

    if (selectedCount === options.length) {
      return [t('scheduling.providerFilter.showing'), t('scheduling.providerFilter.allProviders')];
    }

    return [
      t('scheduling.providerFilter.showing'),
      t('scheduling.providerFilter.nProviders', { count: selectedCount }),
    ];
  })();

  if (isLoading) {
    return <FilterLoading />;
  }

  if (error) {
    return (
      <Text style={styles.error} size="xl">
        {t('scheduling.providerFilter.error')}
      </Text>
    );
  }

  const { className: triggerClassName, inlineStyle: triggerInlineStyle } = getStylesForWeb(styles.trigger);

  return (
    <MultiSelect
      options={options}
      triggerContent={
        <button className={triggerClassName} style={triggerInlineStyle}>
          {selectedCount === 0 && options.length > 0 ? (
            <Text style={styles.error} size="xl">
              {t('scheduling.providerFilter.selectAtLeastOneProvider')}
            </Text>
          ) : (
            <>
              <Text size="xl" numberOfLines={1}>
                {staticLabel}
                {triggerLabel && ' '}
                {triggerLabel && (
                  <Text size="xl" fontStyle="bold" style={styles.triggerLabel}>
                    {triggerLabel}
                  </Text>
                )}
              </Text>
              {triggerLabel && <MaterialIcon source="keyboard-arrow-down" size={24} color="secondaryTextDark" />}
            </>
          )}
        </button>
      }
      onToggle={onToggle}
      disabled={areAllProvidersObGyn}
    />
  );
};
