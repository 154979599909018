import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import { themedContainerStyles } from './styles';

export const RecommendedBanner = () => {
  const [styles] = useTheme(themedContainerStyles);
  const { t } = useTranslation();

  return (
    <View style={styles.recommendedBanner}>
      <Text style={styles.recommendedBannerText} fontStyle="medium">
        {t('schedulingNew.availabilitySection.recommended')}
      </Text>
    </View>
  );
};
