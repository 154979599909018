import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Button, Text, useBrowserTypeMap } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';

import { useRouteNavigation } from '~modules/routing';
import { LocalImage } from '~modules/ui';

import { unstable_styles as styles } from './InfectionInterstitial.module.css';

import type { SCHEDULING_PAGE_NAME } from '~types';

export const InfectionInterstitial = () => {
  const { t } = useTranslation();
  const { dispatch } = useRouteNavigation<typeof SCHEDULING_PAGE_NAME>();
  const { isMobile } = useBrowserTypeMap();

  return (
    <View style={styles.curatedWrapper}>
      <LocalImage source="plant3" width="100%" style={styles.curatedImage} resizeMode="contain" />
      <Text size="xl" style={styles.text}>
        {t('scheduling.curated')}
      </Text>
      <View style={combineUnstableStyles(styles.ctaWrapper, isMobile && styles.ctaWrapperSticky)}>
        <Button onPress={() => dispatch('curatedSubmit')} type="primary" testID="InfectionInterstitial-Submit">
          {t('continue')}
        </Button>
      </View>
    </View>
  );
};
