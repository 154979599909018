import {
  RECOMMENDATION_PAGE_NAME,
  SCHEDULING_PAGE_NAME,
  TELL_US_MORE_PAGE_NAME,
  VISIT_CATEGORIES_PAGE_NAME,
  VISIT_REASONS_PAGE_NAME,
} from '~types';

import { checkoutRoutes } from './checkout';
import { curatedFlowRoutes } from './curatedFlow';

import type { RoutingConfig, StackParamList } from '~types';

export const initialRoutes = {
  id: 'initial' as const,
  initial: () => ({ name: VISIT_REASONS_PAGE_NAME }),
  routes: {
    [VISIT_CATEGORIES_PAGE_NAME]: {
      on: {
        submit: () => ({ name: VISIT_REASONS_PAGE_NAME }),
      },
      reset: true,
    },
    [VISIT_REASONS_PAGE_NAME]: {
      on: {
        legacySubmit: (_, searchParams) => {
          if (
            searchParams.visit_reason === 'establish_virtual_care' ||
            searchParams.visit_reason === 'establish_care'
          ) {
            return { name: SCHEDULING_PAGE_NAME };
          }

          return { name: RECOMMENDATION_PAGE_NAME };
        },
        submit: () => ({ name: SCHEDULING_PAGE_NAME }),
      },
    },
    [RECOMMENDATION_PAGE_NAME]: {
      on: {
        submit: () => ({ name: SCHEDULING_PAGE_NAME }),
        curatedSubmit: () => curatedFlowRoutes,
        secondary: () => ({ name: SCHEDULING_PAGE_NAME, params: { showAll: 'true' } }),
      },
    },
    [SCHEDULING_PAGE_NAME]: {
      on: {
        submit: () => ({ name: TELL_US_MORE_PAGE_NAME }),
        curatedSubmit: () => curatedFlowRoutes,
      },
    },
    [TELL_US_MORE_PAGE_NAME]: {
      on: {
        submit: () => checkoutRoutes,
      },
    },
  },
} satisfies RoutingConfig<StackParamList>;
