/* src/base.css */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* src/ui/components/Accordion/Accordion.module.css */
.Accordion_expandClickableArea {
  position: initial;
  z-index: 1;
}
.Accordion_expandClickableArea:before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}
.Accordion_caret {
  z-index: 0;
}
.Accordion_rightAction {
  z-index: 2;
}
.Accordion_collapsable {
  interpolate-size: allow-keywords;
  transition: all 0.3s ease allow-discrete;
  height: auto;
  overflow: hidden;
  display: block;
}
.Accordion_hidden {
  height: 0;
  display: none;
}

/* src/ui/components/ActivityIndicator/ActivityIndicator.module.css */
@keyframes ActivityIndicator_spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ActivityIndicator_pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.ActivityIndicator_spinner {
  animation: ActivityIndicator_spin 1s linear infinite;
  border-left-color: #7b9bd1;
  border-right-color: #7b9bd1;
  border-top-color: #7b9bd1;
  border-bottom-color: transparent;
}
@media (prefers-reduced-motion: reduce) {
  .ActivityIndicator_spinner {
    animation: ActivityIndicator_pulse 1.5s ease-in-out infinite;
    border-bottom-color: #7b9bd1;
  }
}

/* src/ui/components/Checkbox/Checkbox.module.css */
.Checkbox_container {
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.Checkbox_checkbox {
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  padding: 0;
  border: 2px solid var(--color-primary);
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.Checkbox_checkbox[aria-checked=true] {
  background-color: var(--color-primary);
  border-width: 0;
}
.Checkbox_checkbox:not([disabled]):hover::before {
  background-color: var(--color-primary);
  opacity: 0.1;
  position: absolute;
  inset: 0;
  display: block;
  content: "";
}
.Checkbox_checkbox[disabled] {
  opacity: 0.6;
}
.Checkbox_label {
  flex-shrink: 1;
}

/* src/ui/components/Select/Select.module.css */
.Select_optionContainer {
  padding: var(--option-inline-padding);
  text-decoration: none;
  user-select: none;
}
:is(.Select_optionContainerPickerPopover) {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
}
:is(.Select_optionContainerPickerPopover:hover, .Select_optionContainerPickerPopover:has(:focus-visible)),
.Select_optionContainer[data-highlighted] {
  background: var(--color-border-light);
  outline: none;
}
:is(.Select_optionContainerPickerPopover:hover, .Select_optionContainerPickerPopover:has(:focus-visible)) .Select_optionText,
.Select_optionContainer[data-highlighted] .Select_optionText {
  font-family: CircularStd-Medium;
}
.Select_optionTextInlinePadding {
  padding-inline: var(--option-inline-padding);
}
.Select_optionText {
  display: inline-grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
.Select_optionText:after {
  content: attr(data-text);
  content: attr(data-text) / "";
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  font-family: CircularStd-Medium;
}
.Select_itemIndicator {
  margin-left: var(--option-inline-padding);
  display: inline-block;
  align-self: center;
}
.Select_itemIndicator + .Select_optionText {
  padding-left: 8px;
}
:where(.Select_htmlList) {
  list-style: none;
  padding: 0;
  margin: 0;
}
:where(.Select_htmlListItem) {
  padding: 0;
  margin: 0;
}
.Select_groupLabel {
  user-select: none;
  color: var(--color-primary);
  padding-inline: var(--option-inline-padding);
  display: block;
}
.Select_stickyGroupLabel {
  padding: 12px var(--option-inline-padding);
  position: sticky;
  top: 0;
  background: var(--color-background);
  z-index: 1;
  margin-top: 1px;
}
.Select_optionContainer {
  width: 100%;
  box-sizing: border-box;
}
.Select_optionContainerToUnderline {
  position: relative;
  display: flex;
  align-items: center;
}
:is(.Select_groupContainer:not(:last-child) .Select_optionContainerToUnderline, .Select_groupContainer:last-child .Select_optionContainerToUnderline:not(:last-child), .Select_popover:not(:has(.Select_groupContainer)) .Select_optionContainerToUnderline:not(:last-child), .Select_stickyGroupLabel):after {
  content: "";
  display: block;
  width: calc(100% - var(--option-inline-padding) * 2);
  height: 1px;
  background: var(--color-border-light);
  grid-column: 1 / -1;
  position: absolute;
  bottom: -0.5px;
  left: var(--option-inline-padding);
}
.Select_popover {
  background: var(--color-background);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: var(--box-shadow);
  min-width: max-content;
  overflow: hidden;
  --option-inline-padding: 12px;
  display: flex;
}
.Select_trigger {
  cursor: pointer;
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
}
.Select_srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.Select_scrollAreaRoot {
  width: 100%;
  max-height: var(--radix-popover-content-available-height, 100%);
}
.Select_scrollAreaViewport {
  width: 100%;
  height: 100%;
  max-height: var(--radix-select-content-available-height, initial);
}
.Select_scrollAreaScrollbar {
  width: 4px;
  padding: 5px 2px;
  z-index: 2;
}
.Select_scrollAreaThumb {
  background: var(--color-divider);
  border-radius: 3px;
}

/* src/ui/components/MultiSelect/MultiSelect.module.css */
.MultiSelect_itemContainer:has([aria-checked=true]) {
  background-color: var(--color-border-light);
}
.MultiSelect_itemContainer {
  padding: 0;
}
.MultiSelect_itemContainer > * {
  padding: var(--option-inline-padding);
  padding-left: calc(var(--option-inline-padding) * 2);
}
.MultiSelect_trigger:disabled {
  cursor: initial;
}

/* src/ui/components/ToastNotification/ToastNotificationList.module.css */
.ToastNotificationList_container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 24px;
  gap: 8px;
}
.ToastNotificationList_item {
  padding: 8px;
  border-radius: 8px;
  transition: transform 0.3s ease-out;
  transform: none;
}
@starting-style {
  .ToastNotificationList_item {
    transform: translateX(calc(100% + 24px));
  }
}
.ToastNotificationList_infoTheme {
  background-color: var(--color-info);
}
.ToastNotificationList_errorTheme {
  color: var(--color-background);
  background-color: var(--color-error);
}
.ToastNotificationList_text {
  color: inherit;
}
.ToastNotificationList_srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
/*# sourceMappingURL=index.css.map */