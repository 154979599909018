.scrollIcon {
  --size: 44px;
  position: fixed;
  top: calc(var(--navigation-bar-mobile-height) - var(--size) / 2);
  right: 20;
  background-color: var(--color-background);
  width: var(--size);
  height: var(--size);
  z-index: 2;
  border-radius: calc(var(--size) / 2);
  border-width: 1px;
  border-color: var(--color-border-text-input);
  box-shadow: var(--box-shadow);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}

.visible {
  opacity: 1;
  transform: scale(1);
}
