import { sizes, styleSheetFactory } from '@almond/ui';

import { gradientBackground } from '~modules/ui';

export default styleSheetFactory(theme => ({
  scrollContainer: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  horizontalPadding: {
    paddingHorizontal: sizes.SPACING_L,
  },
  verticalCenter: {
    flex: 1,
    maxWidth: '100%',
    paddingVertical: sizes.SPACING_M,
  },
  verticalCenterWithTitle: {
    paddingTop: 64,
  },
  titleDesktop: {},
  titleMobile: {
    marginHorizontal: sizes.SPACING_L,
  },
  content: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    maxWidth: '100%',
    marginHorizontal: 'auto',
    justifyContent: 'center',
    marginTop: sizes.SPACING_M,
  },
  absoluteComponent: {
    position: 'absolute',
  },

  footerContentMobile: {
    paddingVertical: sizes.SPACING_S,
  },
  footer: {
    alignItems: 'center',
    maxWidth: '100%',
    gap: sizes.SPACING_M,
  },

  stickyFooter: {
    paddingVertical: sizes.SPACING_S,
    position: 'sticky' as any,
    bottom: 0,
    width: '100%',
    minWidth: '100vw' as any,
  },
  stickyFooterBackground: {
    ...gradientBackground(theme),
  },
  contactUs: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 0,
  },
}));
