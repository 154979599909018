import { sizes, styleSheetFactory } from '@almond/ui';

const ARROW_BUTTON_SIZE = sizes.SPACING_XL;

export default styleSheetFactory(theme => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: sizes.SPACING_S,
    paddingHorizontal: sizes.SPACING_M,
  },

  containerMobile: {
    alignItems: 'center',
    rowGap: sizes.SPACING_M,
  },

  containerSingle: {
    justifyContent: 'center',
  },

  containerMultiple: {
    justifyContent: 'space-between',
  },

  arrowButton: {
    width: ARROW_BUTTON_SIZE,
    height: ARROW_BUTTON_SIZE,
    borderRadius: ARROW_BUTTON_SIZE / 2,
    backgroundColor: theme.lightPrimary,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 120,
  },

  left: {
    left: -ARROW_BUTTON_SIZE / 2,
    transform: [{ rotate: '180deg' }],
  },

  right: {
    right: -ARROW_BUTTON_SIZE / 2,
  },
}));
