import React, { useCallback } from 'react';
import { View } from 'react-native';

import { BaseButton, Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

import type { DateMode } from '@almond/date-and-time';
import type { Dayjs } from 'dayjs';

export type DateProps = {
  date: Dayjs;
  mode: DateMode;
  onPress?: (date: Dayjs) => void;
};

export const Date: React.FC<DateProps> = props => {
  const { onPress } = props;
  const [styles] = useTheme(themedStyles);
  const modeCapitalized = `${props.mode.charAt(0).toUpperCase()}${props.mode.slice(1)}` as Capitalize<DateMode>;
  const dateStyleKey = `date${modeCapitalized}` as const;
  const dateTextStyleKey = `dateText${modeCapitalized}` as const;
  const dateStyles = [styles.dateDefault, styles[dateStyleKey]];
  const dateTextStyles = [styles.dateTextDefault, styles[dateTextStyleKey]];
  const fontStyle = props.mode === 'current' ? 'bold' : 'medium';

  const handlePress = useCallback(() => {
    onPress?.(props.date);
  }, [onPress, props.date]);

  return (
    <View style={styles.dateContainer}>
      <BaseButton
        style={dateStyles}
        onPress={props.mode === 'disabled' ? undefined : handlePress}
        isDisabled={props.mode === 'disabled'}
        testID="Calendar_Date"
      >
        <Text style={dateTextStyles} fontStyle={fontStyle}>
          {props.date.get('date')}
        </Text>
      </BaseButton>
    </View>
  );
};
