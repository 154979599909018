import React from 'react';

import { ActivityIndicator } from '@almond/ui';
import { Redirect, useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';
import { useGate } from 'statsig-react';

import { withEnforceAuthWhenNecessary } from '~modules/navigation';
import { isBaselineProduct } from '~modules/product';
import { userAtom } from '~modules/state';
import { Background } from '~modules/ui';

export default withEnforceAuthWhenNecessary(() => {
  const { isAdmin } = useRecoilValue(userAtom);
  const localSearchParams = useLocalSearchParams();
  const searchParams = new URLSearchParams(localSearchParams);
  const schedulingV2Enabled = useGate('scheduling_v2');

  if (schedulingV2Enabled.isLoading) {
    return (
      <Background>
        <ActivityIndicator />
      </Background>
    );
  }

  if (isBaselineProduct(searchParams.get('product'))) {
    return <Redirect href={`/demographic?${searchParams}`} />;
  }

  if (isAdmin && !schedulingV2Enabled.value) {
    return <Redirect href={`/visit-categories?${searchParams}`} />;
  }

  return <Redirect href={`/visit-reasons?${searchParams}`} />;
});
