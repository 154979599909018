import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { KnowYourProviderOut } from '@almond/api-types';

export type PhysicianInfoProps = {
  info: KnowYourProviderOut;
};

export const PhysicianInfo: React.FC<PhysicianInfoProps> = props => {
  const { t } = useTranslation();
  const [styles] = useTheme(themedStyles);
  const { info } = props;

  return (
    <View style={styles.container}>
      <Text size="m" style={styles.darkText}>
        {info.bio}
        {'\n\n'}
        {info.experience && (
          <>
            <Text fontStyle="bold" size="m" style={styles.darkText}>
              {t('schedulingNew.availabilitySection.experience')}
            </Text>
            <Text size="m" style={styles.darkText}>
              {info.experience}
            </Text>
            {'\n\n'}
          </>
        )}
        {info.education && (
          <>
            <Text fontStyle="bold" size="m" style={styles.darkText}>
              {t('schedulingNew.availabilitySection.education')}
            </Text>
            <Text size="m" style={styles.darkText}>
              {info.education}
            </Text>
          </>
        )}
      </Text>
    </View>
  );
};
