import React, { useMemo } from 'react';
import { View } from 'react-native';

import { useTrackAnalyticsEvent } from '@almond/analytics';
import { useTranslation } from '@almond/localization';
import { ActivityIndicator, Button, ScrollView, Text, useBrowserTypeMap } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useRecoilValue } from 'recoil';

import { NavigationBar } from '~modules/integration';
import { Container } from '~modules/layout';
import { useRouteNavigation } from '~modules/routing';
import { appointmentParamsAtom } from '~modules/state';
import { Background, LocalImage, RecommendationMessage } from '~modules/ui';

import { VisitReasonHeaderMobile, VisitReasonSwitcher } from '../../components';
import { useRecommendationBodyText, useVisitReasons } from '../../hooks';
import { appointmentUtilities, getProviderTypeString } from '../../services';
import { Providers } from './Providers';
import { unstable_styles as styles } from './RecommendationPage.module.css';
import { useGetPhysicians } from './useGetPhysicians';

import type { RECOMMENDATION_PAGE_NAME } from '~types';

export const RecommendationPage: React.FC = () => {
  const { isMobile } = useBrowserTypeMap();
  const { dispatch } = useRouteNavigation<typeof RECOMMENDATION_PAGE_NAME>();
  const { t } = useTranslation();
  const appointmentParamsState = useRecoilValue(appointmentParamsAtom);
  const searchParams = useLocalSearchParams();
  const { visitReason } = useVisitReasons();
  const visitOutcome = visitReason?.visitOutcome;
  const bodyText = useRecommendationBodyText(visitOutcome);
  const trackAnalyticsEvent = useTrackAnalyticsEvent();

  // Load physicians in parallel with the visit reasons API
  useGetPhysicians();

  const disableSwitching = searchParams.infectionOutcome === 'true';
  const isCuratedFlow =
    appointmentUtilities.isCuratedFlow(appointmentParamsState, searchParams.visit_reason) &&
    searchParams.infectionOutcome !== 'true';

  const subtitle = useMemo(() => {
    if (!visitOutcome || isCuratedFlow) {
      return null;
    }

    let location: 'office' | 'video' | 'anyLocation' = visitOutcome.isTelehealth ? 'video' : 'office';

    if (visitOutcome.isTelehealth === null) {
      location = 'anyLocation';
    }

    const locationText = t(`visitType.${location}Visit`);

    return t('recommendation.subtitle', {
      location: locationText,
      provider: visitOutcome.providerTypes
        ? getProviderTypeString(visitOutcome.providerTypes)
        : t('recommendation.anyProvider'),
    });
  }, [visitOutcome, isCuratedFlow, t]);

  if (isCuratedFlow) {
    return (
      <Background noBackgroundGradient>
        <NavigationBar center={isMobile && <VisitReasonHeaderMobile disableSwitching={disableSwitching} />} />
        <ScrollView>
          {!isMobile && <VisitReasonSwitcher disableSwitching={disableSwitching} />}
          <View style={styles.curatedWrapper}>
            <LocalImage source="plant3" width="100%" style={styles.curatedImage} resizeMode="contain" />
            {/* Treat this text as the page "title" for purposes of Cypress tests */}
            <Text size="xl" style={styles.text} testID={`recommendation:${t('recommendation.title')}`}>
              {t('recommendation.curated.body')}
            </Text>
            <View style={styles.ctaWrapper}>
              <Button onPress={() => dispatch('curatedSubmit')} type="primary" testID="Submit">
                {t('recommendation.curated.primary')}
              </Button>
              <Button
                onPress={async () => {
                  const updatedVisitReason = 'general_infection';

                  trackAnalyticsEvent('visit_reasons', { visitReasons: updatedVisitReason });
                  await dispatch('secondary', undefined, { visit_reason: updatedVisitReason, showAll: 'true' });
                }}
                type="secondary"
                testID="Secondary"
              >
                {t('recommendation.curated.secondary')}
              </Button>
            </View>
          </View>
        </ScrollView>
      </Background>
    );
  }

  return (
    <Container
      id="recommendation"
      title={t('recommendation.title')}
      size="XXL"
      backgroundDecoration={!isMobile && <VisitReasonSwitcher disableSwitching={disableSwitching} />}
      navigationBarProps={{
        center: isMobile && <VisitReasonHeaderMobile disableSwitching={disableSwitching} />,
        style: isMobile && styles.navigationBarMobile,
      }}
      backgroundProps={{
        style: isMobile && styles.backgroundMobile,
      }}
    >
      {subtitle && visitOutcome ? (
        <RecommendationMessage subtitle={subtitle} bodyText={bodyText}>
          <Providers visitOutcome={visitOutcome} visitReason={searchParams.visit_reason} />
        </RecommendationMessage>
      ) : (
        <ActivityIndicator />
      )}
      <View style={styles.ctaWrapper}>
        <Button onPress={() => dispatch('submit')} type="primary" testID="Submit">
          {t('recommendation.primary')}
        </Button>
        <Button onPress={() => dispatch('secondary')} type="secondary" testID="Secondary">
          {t('recommendation.secondary')}
        </Button>
      </View>
    </Container>
  );
};
