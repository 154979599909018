import React, { useMemo } from 'react';
import { View } from 'react-native';

import { dateAndTimeUtilities } from '@almond/date-and-time';
import { Text, useTheme } from '@almond/ui';

import themedStyles from './styles';

export const WeekHeader: React.FC = () => {
  const [styles] = useTheme(themedStyles);

  const titles = useMemo(() => {
    return dateAndTimeUtilities.generateWeekTitles();
  }, []);

  return (
    <View style={styles.row}>
      {titles.map((title, index) => (
        <Text key={index} fontStyle="medium" size="m" style={styles.dayOfWeek}>
          {title}
        </Text>
      ))}
    </View>
  );
};
