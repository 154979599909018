import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';
import { useIsFocused } from '@react-navigation/native';
import { Widget } from '@typeform/embed-react';
import { useRecoilValue } from 'recoil';

import { ErrorMessage } from '~modules/errors';
import { NavigationBar } from '~modules/integration';
import { useNavigationBar } from '~modules/patient';
import { patientAtom } from '~modules/state';
import { Background, ContactUs } from '~modules/ui';

import { listenForMessage } from '../../services';

import themedStyles from './styles';

import type { WidgetProps } from '@typeform/embed-react';
import type { z } from 'zod';

export type TypeformWidgetProps<T> = WidgetProps & {
  MockWidget?: React.ComponentType<WidgetProps>;
  onSubmit?: (response: { responseId: string; formId: string }) => void;
  isProd: boolean;
} & ({ onMessage?: (response: T) => void; schema: z.ZodSchema<T> } | { onMessage?: never; schema?: never });

const TypeformWidget = <T,>(props: TypeformWidgetProps<T>) => {
  const { MockWidget, isProd, onSubmit, onMessage, schema, ...widgetProps } = props;
  const WidgetToRender = MockWidget ?? Widget;
  const [styles] = useTheme(themedStyles);
  const { patientUuid } = useRecoilValue(patientAtom);
  const [error, setError] = useState<Error | null>(null);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!schema) {
      return;
    }

    const unregister = listenForMessage(schema, (messageError, message) => {
      if (messageError) {
        setError(messageError);

        return;
      }

      const parsedMessage = schema.safeParse(message);

      if (!parsedMessage.success) {
        return;
      }

      onMessage?.(parsedMessage.data);
    });

    return () => {
      unregister();
    };
    // isFocused is included in this hook because when the user advances to the next page
    // after the Typeform, then presses the back button to return to this page, we
    // need this message listener to re-run so we can listen for the message from
    // the iframe.
  }, [onMessage, schema, isFocused]);

  const hiddenValues = useMemo(() => {
    const values: Record<string, string> = {};

    if (patientUuid) {
      values.patient_uuid = patientUuid;
    }

    if (!isProd) {
      values.is_test = 'true';
    }

    return values;
  }, [patientUuid, isProd]);

  if (error || !patientUuid) {
    return (
      <ErrorMessage
        error={
          error ??
          new Error(
            `Your response has been saved but something went wrong when \
processing your submission. Please contact your care team for help.`
          )
        }
      />
    );
  }

  return (
    // Re-mount when re-focusing. This causes the iframe content to reset
    // back to the initial URL when going back to this page with the Demi
    // back button.
    isFocused && (
      <WidgetToRender
        style={styles.widget}
        inlineOnMobile={true}
        redirectTarget="_self"
        hidden={hiddenValues}
        onSubmit={onSubmit}
        iframeProps={{ style: 'border-top-left-radius: 0px; border-top-right-radius: 0px' }}
        {...widgetProps}
      />
    )
  );
};

const TypeformWidgetWrapper = <T,>(props: TypeformWidgetProps<T> & { testID?: string }) => {
  const { isLoading: isLoadingNavigationBar, bannerText } = useNavigationBar();
  const [styles] = useTheme(themedStyles);
  const { testID, ...restProps } = props;

  return (
    <Background testID={testID} noBackgroundGradient style={styles.background}>
      <NavigationBar isLoading={isLoadingNavigationBar} bannerText={bannerText} />
      <TypeformWidget {...restProps} />
      <View style={styles.footer}>
        <ContactUs style={styles.contactUs} />
      </View>
    </Background>
  );
};

export { TypeformWidgetWrapper as TypeformWidget };
