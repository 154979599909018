import React from 'react';
import { View } from 'react-native';

import { useBrowserTypeMap, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { ViewProps } from 'react-native';

export type SchedulingSectionProps = ViewProps & {
  noShadow?: boolean;
};

export const SchedulingSection = (props: SchedulingSectionProps) => {
  const { children, style, noShadow, ...restProps } = props;
  const [styles] = useTheme(themedStyles);
  const { isDesktop, isMobile } = useBrowserTypeMap();

  return (
    <View
      style={[
        styles.container,
        isDesktop && styles.containerDesktop,
        isMobile && styles.containerMobile,
        !noShadow && styles.shadow,
        style,
      ]}
      {...restProps}
    >
      {children}
    </View>
  );
};
