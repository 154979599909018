import { useCallback, useMemo } from 'react';

import { dateAndTimeParsers } from '@almond/date-and-time';

import { useSchedulingMultiday } from '../../hooks';
import { appointmentUtilities } from '../../services';

import type { MultidayParams } from '../../hooks';
import type { VisitOutcome } from '../../services';
import type { PhysicianFindOut, PhysicianTimeSlots } from '@almond/api-types';
import type { Dayjs } from 'dayjs';

export type PhysicianTimeSlotsWithRecommendation = PhysicianTimeSlots & { isRecommended: boolean };

/**
 *
 * @param selectedDate Currently selected day
 * @param params Params for querying for availability
 * @param visitOutcome
 * @returns Variety of data for rendering selected day's availability
 */
export const useSingleDayData = (
  selectedDate: Dayjs,
  params: MultidayParams | null,
  providers: PhysicianFindOut[],
  visitOutcome: VisitOutcome | undefined
) => {
  const response = useSchedulingMultiday(selectedDate, params);

  const data = useMemo<PhysicianTimeSlotsWithRecommendation[] | null>(() => {
    const selectedDateString = dateAndTimeParsers.toRemoteDate(selectedDate);

    if (!response?.data) {
      return null;
    }

    const timeSlots = response.data.find(d => d.day === selectedDateString)?.physiciansTimeSlots || [];

    return timeSlots
      .filter(timeSlot => {
        return providers.some(provider => provider.uuid === timeSlot.physician.uuid);
      })
      .map(timeSlot => ({
        ...timeSlot,
        isRecommended:
          visitOutcome && 'recommendedProviders' in visitOutcome && visitOutcome.recommendedProviders
            ? visitOutcome.recommendedProviders.some(r => timeSlot.physician.uuid === r)
            : false,
      }));
  }, [response.data, selectedDate, visitOutcome, providers]);

  const sortedData = useMemo<PhysicianTimeSlotsWithRecommendation[]>(() => {
    return appointmentUtilities.sortPhysicians(data || []);
  }, [data]);

  return {
    error: response?.error,
    retry: useCallback(() => response?.retry(), [response]),
    data: sortedData,
    isLoading: response?.isLoading,
  };
};
