import { sizes, styleSheetFactory } from '@almond/ui';

const HEADSHOT_MOBILE_SIZE = 140;

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    flex: 1,
    columnGap: 10,
    justifyContent: 'space-between',
  },

  bioButtonContainer: {
    flexDirection: 'row',
  },

  bioButton: {
    paddingHorizontal: sizes.SPACING_XS,
  },

  bioBtnLabel: {
    color: theme.secondaryTextDark,
    fontSize: sizes.FONT_SIZE_XXS,
  },

  modal: {
    backgroundColor: theme.lightAccent,
  },

  modalContent: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    rowGap: sizes.SPACING_L,
    padding: sizes.SPACING_M,
  },

  content: {
    alignItems: 'center',
    rowGap: sizes.SPACING_S,
  },

  headshot: {
    width: HEADSHOT_MOBILE_SIZE,
    height: HEADSHOT_MOBILE_SIZE,
    borderRadius: HEADSHOT_MOBILE_SIZE / 2,
  },

  darkText: {
    color: theme.darkText,
  },
}));
