import { useCallback } from 'react';

import { useTranslation } from '@almond/localization';
import { useToastNotification } from '@almond/ui';
import { useRecoilState } from 'recoil';

import { waitlistAtom } from './atoms';

import type { WaitlistState } from '~types';

export type WaitlistAction = { type: 'open' } | { type: 'close' } | { type: 'complete' };

const waitlistReducer = (state: WaitlistState, action: WaitlistAction): WaitlistState => {
  switch (action.type) {
    case 'open':
      return { ...state, isVisible: true };
    case 'close':
      return { ...state, isVisible: false };
    case 'complete':
      return { ...state, isComplete: true };
    default:
      throw new Error('Invalid action');
  }
};

export const useWaitlistModalState = () => {
  const [waitlistState, setWaitlistState] = useRecoilState(waitlistAtom);
  const { t } = useTranslation();
  const { showToast } = useToastNotification();

  const dispatch = useCallback(
    (action: WaitlistAction) => {
      setWaitlistState(prevState => waitlistReducer(prevState, action));
      if (action.type === 'complete') {
        showToast({
          text: t('schedulingNew.waitlistJoined'),
          type: 'info',
          duration: 3200,
        });
      }
    },
    [setWaitlistState, showToast, t]
  );

  return [waitlistState, dispatch] as const;
};
