import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@almond/localization';
import { SkeletonLoader, Text, useBrowserTypeMap, useTheme } from '@almond/ui';
import { useToggle } from '@almond/utils';

import { LoadingReadBioButton, ReadBioButtonNew } from '../ReadBioButtonNew';
import { Headshot } from './Headshot';

import { themedPhysicianStyles } from './styles';

import type { PhysicianFindOut, VisitLocationEnum } from '@almond/api-types';

export type PhysicianProps = {
  physician: PhysicianFindOut;
  duration: number;
  location: VisitLocationEnum;
  isLoading: false;
};

export type PhysicianLoadingProps = Omit<Partial<PhysicianProps>, 'isLoading'> & { isLoading: true };

const LoadingPhysician: React.FC = () => {
  const [styles] = useTheme(themedPhysicianStyles);
  const { isMobile } = useBrowserTypeMap();

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      <SkeletonLoader.Circle diameter={isMobile ? 65 : 80} />
      <View style={styles.contentContainer}>
        <SkeletonLoader.Rectangle width="50%" height={isMobile ? 18 : 23} />
        <SkeletonLoader.Rectangle width="50%" height={18} />
        <LoadingReadBioButton style={styles.readBioButton} />
      </View>
    </View>
  );
};

export const Physician: React.FC<PhysicianProps | PhysicianLoadingProps> = props => {
  const [styles] = useTheme(themedPhysicianStyles);
  const { t } = useTranslation();
  const { isMobile } = useBrowserTypeMap();
  const [isVisible, toggleIsVisible] = useToggle(false);

  if (props.isLoading) {
    return <LoadingPhysician />;
  }

  const { physician, duration, location } = props;

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      <Headshot physician={physician} onPress={toggleIsVisible} />
      <View style={styles.contentContainer}>
        <Text style={styles.title} fontStyle="bold" size={isMobile ? 'm' : 'xl'}>
          {`${physician.firstName} ${physician.lastName}, ${physician.title}`}
        </Text>
        <Text style={styles.subtitle} size="m">
          {t('schedulingNew.availabilitySection.subtitleTemplate', {
            visitType: t(`schedulingNew.availabilitySection.${location === 'Video' ? 'videoVisit' : 'officeVisit'}`),
            duration,
          })}
        </Text>
        <ReadBioButtonNew
          style={styles.readBioButton}
          physician={physician}
          isVisible={isVisible}
          toggleIsVisible={toggleIsVisible}
        />
      </View>
    </View>
  );
};
